import { useEffect, useState } from "react";

import { Message } from "primereact/message";

import useAxios from "../../hooks/useAxios";
import useTokenData from "../../hooks/useTokenData";

function ServiceStatusLabel() {
  const { http } = useAxios();
  const [showBanner, setShowBanner] = useState(false);
  const { IsEntityApproved } = useTokenData();
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  const markBannerAsRead = async () => {
    if (doNotShowAgain) {
      try {
        http.put("/EntityUser/MarkBannerAsRead");
      } catch (err) {}
    }
  };

  const data = {
    Pending: {
      severity: "info",
      summary: "Practice Pending Approval",
      icon: "pi pi-info-circle",
      color: "#5995f7",
      detail:
        "This practice is currently pending approval. Until the approval process is completed, you won`t be able to publish your services. We appreciate your patience!",
    },
    Approved: {
      severity: "success",
      summary: "Practice Approved",
      icon: "pi pi-check",
      color: "#1da579",
      detail:
        "This practice has been approved, you are now able to publish and order services.",
    },
    Denied: {
      severity: "warn",
      summary: "Practice Denied",
      icon: "pi pi-exclamation-triangle",
      color: "#ddad67",
      detail:
        "This practice has been denied, you won`t be able to publish your services.",
    },
    InComplete: {
      severity: "warn",
      summary: "Attention",
      icon: "pi pi-exclamation-triangle",
      color: "#ddad67",
      detail:
        "Complete identity verification to ensure your practice can undergo admin review.",
    },
  };

  const notifTemplate = (currentStatus) => (
    <div className="flex w-full justify-between">
      <div className="flex items-center gap-2">
        <div className="flex items-center justify-center">
          <i className={`${currentStatus.icon} text-2xl`}></i>
        </div>
        <div className="m-2 flex w-full items-center gap-3">
          <strong>{currentStatus.summary}</strong>
          <span
            className={`hidden border-l px-2 md:inline`}
            style={{ borderColor: currentStatus.color }}
          >
            {currentStatus.detail}
          </span>
        </div>
      </div>
      <div className="flex items-center justify-between gap-5 px-4">
        <span className="flex items-center gap-1">
          {doNotShowAgain ? (
            <i
              onClick={() => setDoNotShowAgain((prev) => !prev)}
              className="pi pi-check-square mr-2 cursor-pointer text-lg font-bold"
            ></i>
          ) : (
            <i
              onClick={() => setDoNotShowAgain((prev) => !prev)}
              className="pi pi-stop mr-2 cursor-pointer text-lg font-bold"
            ></i>
          )}
          <span
            onClick={() => setDoNotShowAgain((prev) => !prev)}
            className="cursor-pointer whitespace-nowrap underline"
          >
            Don't show again
          </span>
        </span>
        <i
          className="pi pi-times cursor-pointer"
          onClick={() => {
            setShowBanner(false);
            markBannerAsRead();
          }}
        ></i>
      </div>
    </div>
  );

  useEffect(() => {
    setShowBanner(true);
    setDoNotShowAgain(false);
  }, [IsEntityApproved]);

  const currentStatus = data[IsEntityApproved];

  if (!currentStatus) return;

  return (
    showBanner && (
      <div className="card mb-4 w-full">
        <Message
          style={{
            borderLeft: `6px ${currentStatus.color} solid`,
          }}
          className="flex w-full justify-start p-4 shadow-sm"
          severity={currentStatus.severity}
          content={() => notifTemplate(currentStatus)}
        />
      </div>
    )
  );
}

export default ServiceStatusLabel;
