import { useEffect, useRef, useState } from "react";

import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import { Dialog } from "primereact/dialog";
import { Sidebar } from "primereact/sidebar";
import { TieredMenu } from "primereact/tieredmenu";
import { RadioButton } from "primereact/radiobutton";

import useAxios from "../hooks/useAxios";
import ReusableSidebar from "./ReusableSidebar";
import useTokenData from "../hooks/useTokenData";
import LogoIcon from "../iconComponents/LogoIcon";
import SignalR from "../components/signalR/SignalR";
import useHandleResize from "../hooks/useHandleResize";
import useImpersonating from "../hooks/useImpersonating";
import AdminSignalR from "../components/signalR/AdminSignalR";
import {
  useBlockNavigation,
  useChecklist,
  useCurrentEntity,
  useDefaultEntity,
  useFeesEntity,
  useFirstTimeVisit,
  useRefreshTokenStore,
  useStoreEntityIdFromUrl,
  useStoreRenderCounter,
  useSwitchOrdering,
  useTokenStore,
} from "../store/store";
function NavBar() {
  const {
    userId,
    entityId,
    VerifyProvider,
    userRoles,
    userName,
    entityType,
    isOwner,
  } = useTokenData();
  const { setShouldUpdateProgress } = useChecklist();
  const [visible, setVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const { setServiceFees } = useFeesEntity();
  const { setCurrentEntity } = useCurrentEntity();
  const { setEntityId } = useStoreEntityIdFromUrl();
  const { setIsFirstTimeVisit } = useFirstTimeVisit();
  const location = useLocation();
  const isAdmin = location.pathname.includes("admin");
  const [userEntities, setUserEntities] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState();
  const menuLift = useRef(null);
  const navigate = useNavigate();
  const { token: currentToken, setToken } = useTokenStore();
  const { refreshToken: currentRefreshToken, setRefreshToken } =
    useRefreshTokenStore();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const { isTablet } = useHandleResize();
  const { http } = useAxios();
  const { isSystemAdminImpersonating } = useImpersonating();
  const { defaultEntity, setDefaultEntity } = useDefaultEntity();
  const { setCountRender } = useStoreRenderCounter();
  const { isOrderingMode, setIsOrderingMode } = useSwitchOrdering();
  const { setBlockNavigation } = useBlockNavigation();
  const [defaultEntitySrc, setDefaultEntitySrc] = useState();

  useEffect(() => {
    const idEntity = location.pathname.split("/").pop();
    setEntityId(idEntity);
  }, [location, setEntityId]);

  let isOredering;
  // fetch entity data from api
  const getEntity = async () => {
    try {
      const response = await http.get(`/Entities/${entityId}`);
      const entitiesData = response.data;
      setCurrentEntity(entitiesData);
      setDefaultEntity(entitiesData);
      setDefaultEntitySrc(entitiesData.imageUrl);
      setServiceFees(entitiesData.fees);
    } catch (error) {
      console.error(error);
    }
  };

  // fetch entities data from api
  const getEntities = async () => {
    try {
      const response = await http.get(`/Users/GetUserEntitiesById/${userId}`);
      const entitiesData = response.data?.entities;
      setUserEntities(entitiesData);
    } catch (error) {
      console.error(error);
    }
  };
  const switchToOrderingMode = () => {
    isOredering = !isOrderingMode;
    const currentRoute = isOredering ? `/ordering/FindServices` : `/Dashboard/`;
    navigate(currentRoute);
  };

  useEffect(() => {
    window.location.pathname === `/ordering/FindServices`
      ? setIsOrderingMode(true)
      : window.location.pathname === `/Dashboard/` && setIsOrderingMode(false);
  }, [window.location.pathname]);

  useEffect(() => {
    if (entityId) {
      getEntities();
      getEntity();
    }
  }, [entityId, userId, currentToken]);
  const logOut = async () => {
    try {
      await axios.post(
        "/Authentication/LogOut",
        {
          refreshToken: currentRefreshToken,
        },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
          "Content-Type": "application/json",
        },
      );
    } catch (err) {
      console.error(err);
    }
    setToken(null);
    setRefreshToken(null);
    setEntityId(null);

    navigate(
      isAdmin || isSystemAdminImpersonating ? "/admin/login" : "/login",
      {
        replace: true,
      },
    );
  };

  const handleEntityClick = async (entity) => {
    if (entityId === entity.entityId) return;
    await http
      .post(
        `/Authentication/GetToken/${entity.entityId}/${entity.lastDashboardView}`,
      )
      .then(async (res) => {
        setBlockNavigation(false);
        const token = res.data.token;
        const refreshToken = res.data.refreshToken;
        setSelectedEntity(entity);
        setCurrentEntity(entity);
        setToken(token);
        setRefreshToken(refreshToken);
        setIsOrderingMode(entity.lastDashboardView === 2);
        setShouldUpdateProgress(true);
        if (entity.lastDashboardView === 1) {
          const response = await axios.get(
            `/Orders/GetHoldedOrdersCount/${entity.entityId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
          setCountRender(response?.data);
        }
        if (entity.lastDashboardView === 2) {
          navigate(`/ordering/FindServices`);
        } else if (
          res.data.userJourney.actionStatus === 1 &&
          res.data.userJourney.entityType === 1
        ) {
          navigate(`/setUpGroupFinishStep`, { replace: true });
        } else if (
          res?.data?.userJourney?.actionStatus === 1 &&
          res.data.userJourney.entityType === 2
        ) {
          navigate(`/setUpIndividualFinish`, { replace: true });
        } else {
          navigate(`/Dashboard/`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (!isTablet) setShowSidebar(false);
  }, [isTablet]);

  const itemsMenu = [
    {
      label: "Switch Practices",
      items: userEntities.map((entity) => ({
        template: (options) => (
          <div
            onClick={(e) => {
              handleEntityClick(entity);
            }}
            className="m-2 grid w-64 cursor-pointer grid-cols-12 items-center gap-2 rounded-md border border-gray-200 p-2 hover:bg-slate-50"
          >
            <div className="col-span-2">
              {entity.entityImageUrl ? (
                <div className="h-10 w-10 overflow-hidden rounded-full border-2">
                  <img
                    className="h-full w-full object-cover"
                    src={entity.entityImageUrl}
                    alt="entityLogo"
                  />
                </div>
              ) : (
                <Avatar
                  label={entity.entityName[0].toUpperCase()}
                  shape="circle"
                  className="h-10 w-10 bg-blue-500 text-white"
                />
              )}
            </div>
            <div className="col-span-8">
              <p className="px-2 font-Poppins text-sm font-normal capitalize text-gray-700">
                {entity.entityName}
              </p>
            </div>
            <div className="col-span-2 flex items-center justify-center">
              <RadioButton
                inputId={`entityRadio_${entity.entityId}`}
                name="entityRadio"
                value={entity.entityId}
                checked={
                  selectedEntity
                    ? selectedEntity?.entityId === entity.entityId
                    : defaultEntity?.id === entity.entityId
                }
              />
            </div>
          </div>
        ),
      })),
    },

    {
      separator: true,
    },
    {
      label: "Edit My Profile",
      icon: "pi pi-fw  pi-user-edit",
      command: () => {
        if (isOrderingMode) {
          navigate(`/ordering/settings/ProfileSettings`);
        } else {
          navigate(`/rendering/settings/ProfileSettings`);
        }
      },
    },
    {
      label: "Manage Entities",
      icon: "pi pi-fw pi-users",
      command: () => {
        setIsFirstTimeVisit(false);
        navigate(`/verifySuccess?UI=${userId}`);
      },
      arrowIcon: "pi pi-angle-right",
    },
    {
      label: isOrderingMode ? "Switch To Rendering" : "Switch To Ordering",
      icon: "pi pi-arrow-right-arrow-left",
      arrowIcon: "pi pi-arrow-right-arrow-left",
      command: () => {
        switchToOrderingMode();
      },
    },
    {
      label: "Log Out",
      icon: "pi pi-fw pi-power-off",
      command: () => {
        logOut();
      },
    },
  ];

  // Remove Logout incase of admin impersonating
  isSystemAdminImpersonating && itemsMenu.pop();

  const verifyProviderButtonAttrs = {
    Pending: { severity: "secondary", text: true },
    Approved: { severity: "success", text: true },
    Denied: { severity: "danger" },
    InComplete: { severity: "warning" },
  };

  const verifyProviderButtonLabel = {
    Pending: "Pending",
    Approved: "Approved",
    Denied: "Denied",
    InComplete: "Incomplete",
  };

  const handleOkClick = () => {
    if (selectedOption === "verify") {
      isOwner === "True" && entityType === "2"
        ? navigate("/ordering/settings/individualSetting")
        : navigate("/ordering/settings/verifyProvider");
    } else if (selectedOption === "invite") {
      navigate("/ordering/staff");
    }
    setVisible(false);
    setSelectedOption(null);
  };
  return (
    <>
      <>
        <Dialog
          header="Verify Provider"
          draggable={false}
          visible={visible}
          style={{ width: "40vw" }}
          position={"top"}
          onHide={() => {
            if (!visible) return;
            setVisible(false);
          }}
        >
          {userRoles === "Admin" ? (
            <p className="capitalize">
              Choose{" "}
              <span className="font-base text-light-purple">
                'Verify Yourself as provider'{" "}
              </span>{" "}
              to complete your own verification process. If you intend to invite
              someone else as a provider, select{" "}
              <span className="font-base text-light-purple">
                {" "}
                'Invite as Provider'
              </span>{" "}
              provide their email address, and send the invitation.
            </p>
          ) : (
            <>
              <p className="capitalize">
                Do you wish to proceed with verifying yourself as a provider?
              </p>
              <p>
                Choose{" "}
                <span className="font-base text-light-purple">
                  'Verify Yourself as provider'{" "}
                </span>{" "}
                to complete your own verification process.
              </p>
            </>
          )}
          <div className="mt-9">
            <div
              className={`flex ${userRoles === "Admin" ? "justify-evenly" : "justify-start"}`}
            >
              <div className="field-radiobutton">
                <RadioButton
                  inputId="verify"
                  name="option"
                  value="verify"
                  onChange={(e) => setSelectedOption(e.value)}
                  checked={selectedOption === "verify"}
                />
                <label
                  htmlFor="verify"
                  className="pl-2 text-base font-semibold capitalize text-gray-500"
                >
                  Verify Yourself as provider{" "}
                </label>
              </div>
              {userRoles === "Admin" && (
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="invite"
                    name="option"
                    value="invite"
                    onChange={(e) => setSelectedOption(e.value)}
                    checked={selectedOption === "invite"}
                  />
                  <label
                    htmlFor="invite"
                    className="pl-2 text-base font-semibold capitalize text-gray-500"
                  >
                    Invite as Provider
                  </label>
                </div>
              )}
            </div>
            <div className="mt-9 flex justify-end">
              <Button
                label="OK"
                icon="pi pi-check"
                onClick={handleOkClick}
                disabled={!selectedOption}
              />
            </div>
          </div>
        </Dialog>

        <nav className="fixed z-50 w-full bg-light-text">
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex items-center gap-3">
                <Link
                  to={
                    isAdmin
                      ? "admin/ourPractices"
                      : !isOrderingMode
                        ? "/Dashboard"
                        : "ordering/FindServices"
                  }
                >
                  <LogoIcon />
                </Link>
                {!isAdmin && (
                  <div className="flex gap-2">
                    <div className="ml-2 flex items-center justify-center gap-2 rounded-3xl bg-slate-50 px-2 py-1 shadow-sm">
                      <Avatar
                        icon="pi pi-user"
                        shape="circle"
                        className="h-10 w-10 border-2 border-violet-700 bg-white text-violet-700"
                      />
                      <p className="font-semibold text-violet-800">
                        {userName}
                      </p>
                    </div>
                    <div className="font-poppins flex flex-col items-center justify-center rounded-xl pb-1 pl-2 text-xs text-gray-600">
                      Current view:
                      <p className="ml-1">
                        {isOrderingMode
                          ? "Ordering Provider"
                          : "Rendering Provider"}
                      </p>
                      <p
                        className="ml-3 mr-1 mt-1 font-semibold text-blue-600 hover:cursor-pointer hover:underline"
                        onClick={switchToOrderingMode}
                      >
                        {isOrderingMode
                          ? "Switch to Rendering?"
                          : "Switch to Ordering?"}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-1 items-center justify-end">
                <div className="flex items-center">
                  {isAdmin ? (
                    <AdminSignalR />
                  ) : (
                    <div className="flex items-center justify-between gap-6">
                      <Link to={"https://acceleratedhcs.com/"} target="_blank">
                        <span
                          className={`flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-gray-800 bg-white hover:bg-slate-50`}
                        >
                          <i className={`pi pi-question text-sm`} />
                        </span>
                      </Link>
                      <span className="cursor-pointer rounded-md text-sm font-medium text-normal-text">
                        <SignalR />
                      </span>
                    </div>
                  )}
                  <Button
                    icon="pi pi-align-justify"
                    onClick={() => setShowSidebar(true)}
                    className="lg:hidden"
                    link
                  />
                  <div className={`${isAdmin ? "lg:ml-0" : "lg:ml-6 lg:flex"}`}>
                    <div className="flex space-x-4">
                      <div className="hidden lg:ml-6 lg:block">
                        <div className="flex space-x-4">
                          <div className="flex items-center">
                            <div>
                              {!isOrderingMode &&
                                defaultEntity &&
                                !isAdmin &&
                                (defaultEntity.maxOrdersQuantity || null) && (
                                  <p className="rounded-lg border-[1px] border-red-700 p-3 text-center font-thin text-red-600">
                                    The max number orders that your practice can
                                    take is set to
                                    <span className="font-bold">
                                      {" "}
                                      {defaultEntity.maxOrdersQuantity}{" "}
                                    </span>
                                    to change it
                                    {userRoles === "Admin" && (
                                      <Link
                                        className="pl-1 text-center text-blue-600"
                                        to={
                                          "/rendering/settings/OrderSettings/"
                                        }
                                      >
                                        Click Here
                                      </Link>
                                    )}
                                  </p>
                                )}

                              {isOrderingMode && !isAdmin ? (
                                <Button
                                  className={`border-none`}
                                  {...verifyProviderButtonAttrs[VerifyProvider]}
                                  disabled={
                                    VerifyProvider === "Pending" ||
                                    VerifyProvider === "Approved"
                                  }
                                  onClick={() => {
                                    if (
                                      VerifyProvider === "NULL" ||
                                      VerifyProvider === "Denied" ||
                                      VerifyProvider === "InComplete"
                                    ) {
                                      setVisible(true);
                                    }
                                  }}
                                  icon="pi pi-verified"
                                  label={
                                    verifyProviderButtonLabel[VerifyProvider] ||
                                    "verify yourself as a provider"
                                  }
                                />
                              ) : null}
                            </div>
                            {isAdmin ? (
                              <>
                                <Avatar
                                  label={userName[0]}
                                  shape="circle"
                                  className="mr-2 h-10 w-10 bg-blue-500 text-white"
                                />
                                <p className="mr-11 font-inter">{userName}</p>
                                <p
                                  className="cursor-pointer rounded-md border border-transparent p-2 font-inter hover:border-light-purple"
                                  onClick={logOut}
                                >
                                  <span className="pi pi-fw pi-power-off pr-5 text-sm font-semibold text-light-purple"></span>
                                  Log out
                                </p>
                              </>
                            ) : (
                              <div className="flex gap-2 rounded-md px-3 py-2 text-sm font-medium text-normal-text">
                                <span>
                                  <div
                                    className={`${isMenuOpen ? "p-menu" : ""}`}
                                  >
                                    {userEntities.length < 3 ? (
                                      <Menu
                                        model={itemsMenu}
                                        popup
                                        ref={menuLift}
                                        onSelect={(event) => {
                                          setIsMenuOpen(false);
                                        }}
                                        className="custom-menu p-menu p-tieredmenu px-4"
                                      />
                                    ) : (
                                      <TieredMenu
                                        model={itemsMenu}
                                        popup
                                        ref={menuLift}
                                        onSelect={(event) => {
                                          setIsMenuOpen(false);
                                        }}
                                        className="custom-menu p-menu px-4"
                                      />
                                    )}
                                  </div>

                                  <div
                                    className="flex cursor-pointer items-center justify-center gap-3 rounded-3xl bg-slate-50 px-2 py-1 hover:bg-slate-100 hover:shadow-md"
                                    onClick={(event) => {
                                      setIsMenuOpen(true);
                                      menuLift.current.toggle(event);
                                      setIsMenuOpen(false);
                                    }}
                                  >
                                    <div>
                                      {defaultEntitySrc ||
                                      selectedEntity?.entityImageUrl ? (
                                        <div className="h-10 w-10 overflow-hidden rounded-full border-2">
                                          <img
                                            className="h-full w-full object-cover"
                                            src={
                                              defaultEntitySrc ||
                                              selectedEntity?.entityImageUrl
                                            }
                                            alt="entityLogo"
                                          />
                                        </div>
                                      ) : (
                                        <Avatar
                                          label={
                                            selectedEntity?.entityName[0].toUpperCase() ||
                                            defaultEntity?.name[0].toUpperCase()
                                          }
                                          shape="circle"
                                          className="h-10 w-10 border-2 bg-blue-500 text-white"
                                        />
                                      )}
                                    </div>
                                    <div className="basis-4/5">
                                      <div className="flex items-center justify-center">
                                        <h4 className="font-Poppins capitalize text-gray-600">
                                          {selectedEntity?.entityName ||
                                            defaultEntity?.name}
                                        </h4>
                                      </div>
                                      <p className="font-inter text-sm capitalize text-gray-500">
                                        {isOrderingMode
                                          ? "Ordering"
                                          : "Rendering"}{" "}
                                        Provider
                                      </p>
                                    </div>
                                    <div>
                                      <i className="pi pi-angle-down"></i>
                                    </div>
                                  </div>
                                </span>
                              </div>
                            )}
                            {isAdmin ? null : (
                              <div className="ml-auto">
                                <Button
                                  className="w-[12rem] text-center"
                                  style={{
                                    fontWeight: "500",
                                    background:
                                      "linear-gradient(-225deg, #0077AA 0%, #594FC4 100%)",
                                    border: 0,
                                  }}
                                  onClick={switchToOrderingMode}
                                >
                                  {isOrderingMode
                                    ? "Switch To Rendering"
                                    : "Switch To Ordering"}
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>

        <Sidebar
          visible={showSidebar}
          onHide={() => setShowSidebar(false)}
          className="sidebar"
          position="right"
        >
          <div className="lg:ml-6 lg:flex">
            <div className="flex space-x-4">
              <div className="w-full lg:ml-6 lg:block">
                <div className="flex space-x-4">
                  <div className="w-full">
                    <div className="flex gap-2 rounded-md py-2 text-sm font-medium text-normal-text">
                      <div className={`${isMenuOpen ? "p-menu" : ""}`}>
                        {userEntities.length < 3 ? (
                          <Menu
                            model={itemsMenu}
                            popup
                            ref={menuLift}
                            className="custom-menu .p-menu"
                          />
                        ) : (
                          <TieredMenu
                            model={itemsMenu}
                            popup
                            ref={menuLift}
                            className="custom-menu .p-menu"
                          />
                        )}
                      </div>
                      {!isAdmin && (
                        <Button
                          className="w-full p-0"
                          onClick={(event) => {
                            setIsMenuOpen(true);
                            menuLift.current.toggle(event);
                            setIsMenuOpen(false);
                          }}
                          aria-haspopup
                          link
                          pt={{
                            root: { className: "" },
                          }}
                        >
                          <div className="flex w-full items-center gap-2">
                            <div>
                              {defaultEntitySrc ||
                              selectedEntity?.entityImageUrl ? (
                                <img
                                  className="md:w-15 md:h-15 lg:w-15 lg:h-15 inline h-8 w-8 rounded-full object-cover"
                                  src={
                                    defaultEntitySrc ||
                                    selectedEntity?.entityImageUrl
                                  }
                                  alt="entityLogo"
                                />
                              ) : (
                                <Avatar
                                  label={
                                    selectedEntity?.entityName[0] ||
                                    defaultEntity?.name[0]
                                  }
                                  shape="circle"
                                  className="bg-blue-500 text-white"
                                />
                              )}
                            </div>
                            <div className="basis-4/5">
                              <div className="flex items-center justify-between">
                                <h4 className="font-Poppins capitalize text-gray-500">
                                  {selectedEntity?.entityName ||
                                    defaultEntity?.name}
                                </h4>
                                <i className="pi pi-angle-down pl-2"></i>
                              </div>
                              <p className="text-left font-inter capitalize text-gray-500">
                                {isOrderingMode ? "Ordering" : "Rendering"}
                                Provider
                              </p>
                            </div>
                          </div>
                        </Button>
                      )}
                    </div>

                    <div>
                      {!isOrderingMode &&
                        defaultEntity &&
                        (defaultEntity.maxOrdersQuantity || null) && (
                          <p className="rounded-lg border-[1px] border-red-700 p-3 text-center font-thin text-red-600">
                            The max number orders that your practice can take is
                            set to
                            <span className="font-bold">
                              {" "}
                              {defaultEntity.maxOrdersQuantity}{" "}
                            </span>
                            to change it
                            {userRoles === "Admin" && (
                              <Link
                                className="pl-1 text-center text-blue-600"
                                to={"/rendering/settings/OrderSettings/"}
                              >
                                Click Here
                              </Link>
                            )}
                          </p>
                        )}

                      {isOrderingMode ? (
                        <Button
                          className={`border-none bg-gradient-to-r ${
                            VerifyProvider === "NULL"
                              ? "to-Indigo-600 from-sky-600"
                              : VerifyProvider === "Pending"
                                ? "from-gray-500 to-gray-600"
                                : VerifyProvider === "Approved"
                                  ? "from-green-500 to-green-600"
                                  : VerifyProvider === "Denied"
                                    ? "from-red-400 to-red-700"
                                    : ""
                          }`}
                          disabled={
                            VerifyProvider === "Pending" ||
                            VerifyProvider === "Approved"
                          }
                          onClick={() => {
                            switch (VerifyProvider) {
                              case "NULL":
                                navigate("/ordering/settings/verifyProvider");
                                break;
                              case "Denied":
                                navigate("/ordering/settings/verifyProvider");
                                break;
                              default:
                                break;
                            }
                          }}
                        >
                          <i className="pi pi-verified pr-2"></i>
                          {VerifyProvider === "Pending"
                            ? "Pending"
                            : VerifyProvider === "Approved"
                              ? "Approved"
                              : VerifyProvider === "Denied"
                                ? "Denied"
                                : "Verify Provider"}
                        </Button>
                      ) : null}
                    </div>
                    {isAdmin ? null : (
                      <div className="mb-5 ml-auto mt-3">
                        <Button
                          onClick={switchToOrderingMode}
                          className="w-[12rem] text-center"
                          style={{
                            fontWeight: "500",
                            background:
                              "linear-gradient(-225deg, #0077AA 0%, #594FC4 100%)",
                            border: 0,
                          }}
                        >
                          {isOrderingMode
                            ? "Switch To Rendering"
                            : "Switch To Ordering"}
                        </Button>
                      </div>
                    )}

                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <ReusableSidebar setShowSidebar={setShowSidebar} />
          </div>
        </Sidebar>
      </>
    </>
  );
}

export default NavBar;
