import { useEffect, useImperativeHandle, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import { Dropdown } from "primereact/dropdown";
import { Skeleton } from "primereact/skeleton";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from "primereact/multiselect";
import { ConfirmDialog } from "primereact/confirmdialog";

import useAxios from "../../hooks/useAxios";
import { HeightInput } from "../formElements";
import { useBlockNavigation } from "../../store/store";
import { calculateAge, isNumberEqualCondition } from "../../utils/serviceUtils";

function RequestService({
  setCost,
  services,
  costcalc,
  submitRef,
  serviceId,
  isTesting = false,
  questions,
  generalInfo,
  patientInfo,
  setCptBucket,
  setOrderDocs,
  setActiveIndex,
  calculateCostRef,
  setLocationsBucket,
  requestServiceData,
  submittedResponses,
  skipAnswerQuestions,
  setRequestServiceData,
  setSubmittedResponses,
  setSkipAnswerQuestions,
  setServiceUnavailableDialogVisible,
}) {
  const [responses, setResponses] = useState([
    {
      question: {
        label: "Gender",
      },
      answer: {
        label: patientInfo.gender,
      },
    },
    {
      question: {
        label: "Age",
      },
      answer: {
        label: calculateAge(patientInfo.birth),
      },
    },
  ]);
  const toast = useRef(null);
  const navigate = useNavigate();
  const { http } = useAxios();
  const [visibleQuestions, setVisibleQuestions] = useState([]);
  const [abortDialogData, setAbortDialogData] = useState();
  const [isTechDataLoading, setIsTechDataLoading] = useState(true);
  const [abortDialogVisible, setAbortDialogVisible] = useState(false);

  const {
    handleSubmit,
    control,
    formState,
    watch,
    setValue,
    clearErrors,
    setError,
    reset,
    trigger,
  } = useForm({ mode: "all" });

  useEffect(() => {
    if (submittedResponses && submittedResponses[0]) {
      setResponses(submittedResponses);
      reset(requestServiceData);
    }
  }, []);

  useEffect(() => {
    if (skipAnswerQuestions) {
      onSubmit({});
    } else {
      updateVisibleQuestions(questions);
    }

    return () => skipAnswerQuestions && setSkipAnswerQuestions(false);
  }, [questions, responses]);

  const updateVisibleQuestions = (questions) => {
    const newQuestions = [];
    questions.forEach((question, index) => {
      if (index > 1) {
        if (shouldRenderQuestion(question)) {
          newQuestions.push({ name: "question" + index, ...question });
        }
      }
    });
    setVisibleQuestions(newQuestions);
  };

  const handleInputChange = (question, answer) => {
    let updatedResponses = [...responses];
    const existingResponseIndex = updatedResponses.findIndex(
      (r) => r.question.label === question.label,
    );

    if (question.type === "multipleChoice") {
      if (Array.isArray(answer)) {
        updatedResponses = updatedResponses.filter(
          (r) => r.question.label !== question.label,
        );

        answer.forEach((selectedAnswer) => {
          updatedResponses.push({
            question: { label: question.label },
            answer: { label: selectedAnswer },
            height: question?.constraints?.type === "height" ? true : false,
          });
        });
      }
    } else {
      const updatedResponse = {
        question: { label: question.label },
        answer: { label: answer },
        height: question?.constraints?.type === "height" ? true : false,
      };
      if (existingResponseIndex !== -1) {
        updatedResponses[existingResponseIndex] = updatedResponse;
      } else {
        updatedResponses.push(updatedResponse);
      }
    }
    setResponses(updatedResponses);
  };

  const { setBlockNavigation } = useBlockNavigation();

  const getTechData = async () => {
    setIsTechDataLoading(true);
    try {
      const response = await http.get(
        `/Entities/GetEntityTechnicalData/${serviceId}`,
      );
      setAbortDialogData((prev) => ({ ...prev, techData: response?.data }));
    } catch (error) {
      console.error(error);
    }
    setIsTechDataLoading(false);
  };

  const openAbortDialog = (abortMessage) => {
    setBlockNavigation(false);
    setAbortDialogData((prev) => ({
      ...prev,
      abortMessage: abortMessage,
    }));
    setAbortDialogVisible(true);
    getTechData();
  };

  // Initialize a variable to store the total cost
  let totalCost = 0;
  const calculateServiceCost = async () => {
    const requiredQuestionsAnswered = await trigger();
    if (!requiredQuestionsAnswered) return;
    const servicesCopy = JSON.parse(JSON.stringify(services));
    const metRules = getMetRules(servicesCopy, responses);

    const rulePriorityOrder = {
      abort: 1,
      addCPT: 2,
      AddUnitsToExisting: 3,
      addLocations: 4,
      addDocs: 5,
      removeLocations: 6,
      removeUnitsFromExisting: 7,
      removeCPT: 8,
      removeAllExcept: 9,
      removeMatchingPattern: 10,
      swapCPT: 11,
      removeDocs: 12,
    };
    const sortedMetRules = metRules.sort((a, b) => {
      return rulePriorityOrder[a.action] - rulePriorityOrder[b.action];
    });

    sortedMetRules.forEach((ruleToExecute) => {
      if (ruleToExecute.action === "abort") {
        openAbortDialog(ruleToExecute.abortMessage);
      }
    });
    let originalGeneralInfo = { ...generalInfo };
    let newCPT = JSON.parse(JSON.stringify(originalGeneralInfo.billables));
    let newLocations = JSON.parse(JSON.stringify(generalInfo.locations));

    let newDocuments =
      generalInfo.serviceDocumentsIds &&
      JSON.parse(JSON.stringify(generalInfo.serviceDocumentsIds));

    sortedMetRules.forEach((ruleToExecute) => {
      if (ruleToExecute.action === "removeCPT") {
        newCPT = newCPT.filter(
          (obj2) =>
            !ruleToExecute.billableItems.some((obj1) => obj1.id === obj2.id),
        );
      } else if (ruleToExecute.action === "addCPT") {
        ruleToExecute.billableItems.forEach((bItem) => {
          newCPT.push(bItem);
        });
      } else if (ruleToExecute.action === "removeAllExcept") {
        newCPT = ruleToExecute.billableItems;
      } else if (ruleToExecute.action === "removeMatchingPattern") {
        newCPT = newCPT.filter(
          (obj2) =>
            !ruleToExecute.billableItems.some((obj1) => obj1.id === obj2.id),
        );
      } else if (ruleToExecute.action === "swapCPT") {
        const indexToReplace = newCPT.findIndex(
          (item) => item.id === ruleToExecute.billableItems[0].id,
        );
        const itemToPlace = ruleToExecute.billableItems[1];
        if (indexToReplace !== -1) {
          newCPT.splice(indexToReplace, 1, itemToPlace);
        }
      } else if (ruleToExecute.action === "addDocs") {
        ruleToExecute.documents.forEach((doc) => {
          newDocuments.push(doc);
        });
      } else if (ruleToExecute.action === "removeDocs") {
        newDocuments = newDocuments.filter(
          (doc1Id) =>
            !ruleToExecute.documents.some((doc2Id) => doc1Id === doc2Id),
        );
      } else if (ruleToExecute.action === "addLocations") {
        ruleToExecute.locations.forEach((loc) => {
          newLocations.push(loc);
        });
      } else if (ruleToExecute.action === "removeLocations") {
        newLocations = newLocations.filter(
          (obj2) => !ruleToExecute.locations.some((obj1) => obj1 === obj2),
        );
      } else if (ruleToExecute.action === "AddUnitsToExisting") {
        newCPT.forEach((currUnit) => {
          // Find the matching element in the defaultUnitChange array

          const matchingUnit = ruleToExecute.defaultUnitsChange.find(
            (defUnit) => {
              return defUnit.id === currUnit.id;
            },
          );
          // If a matching element is found, update the units property
          if (matchingUnit) {
            let currentUnits = Number(currUnit.units);
            const changeInUnits = Number(matchingUnit.changeInUnit);
            if (changeInUnits) {
              currentUnits += changeInUnits;
              currUnit.units = currentUnits;
            }
          } else {
            ruleToExecute.billableItems.forEach((bItem) => {
              newCPT.push(bItem);
            });
          }
        });
      } else if (ruleToExecute.action === "removeUnitsFromExisting") {
        newCPT.forEach((currUnit) => {
          // Find the matching element in the defaultUnitChange array
          const matchingUnit = ruleToExecute.defaultUnitsChange.find(
            (defUnit) => defUnit.id === currUnit.id,
          );
          // If a matching element is found, update the units property
          if (matchingUnit) {
            let currentUnits = Number(currUnit.units);
            const changeInUnits = Number(matchingUnit.changeInUnit);
            if (changeInUnits) {
              currentUnits -= changeInUnits;
              currUnit.units = currentUnits;

              // Ensure units do not go below zero
              if (currentUnits <= 0) {
                newCPT = newCPT.filter((item) => item.id !== currUnit.id);
              }
            }
          }
        });
      }
    });

    // Iterate over each element in the newCPT array
    newCPT.forEach((cpt) => {
      // Multiply the chargeAmount by the units for each element
      const units = Number(cpt.units);
      const chargeAmount = Number(cpt.chargeAmount);

      if (units > 0) {
        const costPerCPT = chargeAmount * units;

        // Add the cost of the current CPT to the total cost
        totalCost += costPerCPT;
      }
    });

    if (totalCost <= 0 || newLocations.length <= 0) {
      setServiceUnavailableDialogVisible(true);
      return false;
    } else {
      setCost(totalCost);
      setCptBucket(newCPT);
      setLocationsBucket(newLocations);
      if (setOrderDocs) {
        setOrderDocs(Array.from(new Set(newDocuments)));
      }
      return true;
    }
  };

  useImperativeHandle(costcalc, () => calculateServiceCost);
  const shouldRenderQuestion = (currentQues) => {
    if (!currentQues.show) {
      return true;
    }
    const { action } = currentQues;
    let isMatched = true;

    for (let i = 0; i < currentQues.conditions.length; i++) {
      const condition = currentQues.conditions[i];
      const { question, answer, relation } = condition;
      const questionLabel = question.label.label
        ? question.label.label
        : question.label;
      const answerLabel = answer.label.label
        ? answer.label.label
        : answer.label;

      let userAnswer;

      if (questionLabel === "Gender") {
        userAnswer = [patientInfo.gender];
      } else if (questionLabel === "Age") {
        userAnswer = calculateAge(patientInfo.birth);
      } else {
        userAnswer = watch(
          "question" + questions.findIndex((q) => q.label === questionLabel),
        );
      }

      // And - No action
      if (action === "and" || !action) {
        if (
          (Array.isArray(userAnswer) && userAnswer.length === 0) ||
          !userAnswer
        ) {
          isMatched = false;
          break;
        }
        // And Equal
        if (relation === "equal") {
          if (question.type === "multipleChoice") {
            if (!userAnswer.includes(answerLabel)) {
              isMatched = false;
              break;
            }
          } else if (["number", "decimal"].includes(question.type)) {
            if (!isNumberEqualCondition(userAnswer, condition)) {
              isMatched = false;
              break;
            }
          } else {
            if (userAnswer !== answerLabel) {
              isMatched = false;
              break;
            }
          }
          // And Not Equal
        } else {
          if (question.type === "multipleChoice") {
            if (userAnswer.includes(answerLabel)) {
              isMatched = false;
              break;
            }
          } else if (["number", "decimal"].includes(question.type)) {
            if (isNumberEqualCondition(userAnswer, condition)) {
              isMatched = false;
              break;
            }
          } else {
            if (userAnswer === answerLabel) {
              isMatched = false;
              break;
            }
          }
        }
        // OR
      } else {
        isMatched = false;
        if (
          (Array.isArray(userAnswer) && userAnswer.length === 0) ||
          !userAnswer
        ) {
          continue;
        }
        // Or Equal
        if (relation === "equal") {
          if (question.type === "multipleChoice") {
            if (userAnswer.includes(answerLabel)) {
              isMatched = true;
              break;
            }
          } else if (["number", "decimal"].includes(question.type)) {
            if (isNumberEqualCondition(userAnswer, condition)) {
              isMatched = true;
              break;
            }
          } else {
            if (userAnswer === answerLabel) {
              isMatched = true;
              break;
            }
          }
          // Or Not Equal
        } else {
          if (question.type === "multipleChoice") {
            if (!userAnswer.includes(answerLabel)) {
              isMatched = true;
              break;
            }
          } else if (["number", "decimal"].includes(question.type)) {
            if (!isNumberEqualCondition(userAnswer, condition)) {
              isMatched = true;
              break;
            }
          } else {
            if (userAnswer !== answerLabel) {
              isMatched = true;
              break;
            }
          }
        }
      }
    }

    const questionId =
      "question" + questions.findIndex((q) => q.label === currentQues.label);

    if (!isMatched && watch(questionId)) {
      setResponses(
        responses.filter((r) => r?.question?.label !== currentQues?.label),
      );
      setValue(questionId, undefined);
    }

    return isMatched;
  };

  const onSubmit = async (data) => {
    setRequestServiceData(data);
    setSubmittedResponses(responses);
    const servicesCopy = JSON.parse(JSON.stringify(services));
    const metRules = getMetRules(servicesCopy, responses);
    let isAborted;
    metRules.forEach((ruleToExecute) => {
      if (ruleToExecute.action === "abort") {
        isAborted = true;
        openAbortDialog(ruleToExecute.abortMessage);
      }
    });
    if (isAborted) return;
    const calculatedCost = await calculateServiceCost();
    if (calculatedCost) {
      setActiveIndex((old) => old + 1);
    }
  };

  // Function to check if a single condition is satisfied
  const isConditionSatisfied = (
    ruleCondition,
    questionCondition,
    condition,
    responses,
  ) => {
    const matchingQuestion = responses.find(
      (serviceRule) => serviceRule.question.label === condition.question.label,
    );

    if (questionCondition === "notAnswered" && !matchingQuestion) {
      return true;
    }

    if (!matchingQuestion) return false;

    let isMatchingAnswer;
    if (questionCondition === "notAnswered" && matchingQuestion.answer.label) {
      return false;
    }

    const { question } = condition;

    if (questionCondition === "equal") {
      isMatchingAnswer =
        question.type === "multipleChoice"
          ? responses.filter(
              (serviceRule) =>
                serviceRule.question.label === condition.question.label &&
                serviceRule.answer.label === condition.answer.label,
            ).length
          : matchingQuestion.answer.label === condition.answer.label;
    } else {
      isMatchingAnswer =
        question.type === "multipleChoice"
          ? !responses.filter(
              (serviceRule) =>
                serviceRule.question.label === condition.question.label &&
                serviceRule.answer.label === condition.answer.label,
            ).length
          : matchingQuestion.answer.label !== condition.answer.label;
    }

    if (["number", "decimal"].includes(question.type)) {
      const userNumber = Number(matchingQuestion.answer.label);
      return questionCondition === "equal"
        ? isNumberEqualCondition(userNumber, condition)
        : !isNumberEqualCondition(userNumber, condition);
    }

    // Default case
    return isMatchingAnswer;
  };

  // Function to check if all conditions within a rule are satisfied
  const areAllConditionsSatisfied = (rule, responses) => {
    return rule.ruleCondition === "and"
      ? rule.conditions.every((condition) =>
          isConditionSatisfied(
            rule.ruleCondition,
            condition.questionCondition || "equal",
            condition,
            responses,
          ),
        )
      : rule.conditions.some((condition) =>
          isConditionSatisfied(
            rule.ruleCondition,
            condition.questionCondition || "equal",
            condition,
            responses,
          ),
        );
  };

  // Function to check if a rule is satisfied
  const isRuleSatisfied = (rule, responses) => {
    if (rule.type === "single") {
      return areAllConditionsSatisfied(rule, responses);
    } else if (rule.type === "group") {
      return rule.operator === "and"
        ? rule.rules.every((subRule) => isRuleSatisfied(subRule, responses))
        : rule.rules.some((subRule) => isRuleSatisfied(subRule, responses));
    }
  };

  // Function to get the rules that are satisfied
  const getMetRules = (services, responses) => {
    const metRules = [];

    const checkRules = (services) => {
      services.forEach((rule) => {
        if (isRuleSatisfied(rule, responses)) {
          metRules.push(rule);
        } else if (rule.type === "group") {
          checkRules(rule.rules);
        }
      });
    };

    checkRules(services);

    return metRules;
  };
  // Usage

  const renderHeader = () => {
    return (
      <p className="p-1 text-xs font-semibold text-gray-500">
        Question Description
      </p>
    );
  };

  const header = renderHeader();

  const abortDialogFooter = (
    <div>
      <Button
        label="Ok"
        icon="pi pi-check"
        onClick={() => {
          setBlockNavigation(true);
          setAbortDialogVisible(false);
          if (isTesting) return;
          navigate("/ordering/FindServices");
        }}
        autoFocus
      />
    </div>
  );

  function convertToFeetAndInches(number) {
    const feet = Math.floor(number);
    const inches = Math.round((number - feet) * 12);
    return `${feet} (ft) ${inches} (in)`;
  }

  function limitToThreeDecimalPlaces(value) {
    if (typeof value !== "number" || isNaN(value)) {
      return value;
    }

    const stringValue = value.toString();

    if (
      stringValue.indexOf(".") !== -1 &&
      stringValue.split(".")[1].length > 3
    ) {
      return parseFloat(value.toFixed(3));
    }

    return value;
  }

  return (
    <>
      <form ref={submitRef} onSubmit={handleSubmit(onSubmit)}>
        <Toast ref={toast} />
        {!isTesting && <ConfirmDialog />}
        <Dialog
          visible={abortDialogVisible}
          header="Oops! It looks like there's an issue with your order."
          footer={abortDialogFooter}
          className="w-[50vw]"
          closable={false}
        >
          <div className="flex flex-col gap-8">
            <p className="text-lg font-semibold">
              We're sorry, but it seems that your order doesn't meet the
              necessary requirements at this time.
            </p>
            <Editor
              id="abort-message"
              value={abortDialogData?.abortMessage}
              readOnly
              headerTemplate={
                <span>
                  <i className="pi pi-info-circle mr-2 text-red-400"></i>
                  Provider Message
                </span>
              }
              style={{ height: "120px" }}
            />
            {isTechDataLoading ? (
              <div className="flex flex-col gap-2">
                <Skeleton width="70%" height="24px" />
                <div className="grid grid-cols-3 gap-4">
                  <Skeleton width="100%" height="24px" />
                  <Skeleton width="100%" height="24px" />
                  <Skeleton width="100%" height="24px" />
                </div>
              </div>
            ) : (
              abortDialogData?.techData && (
                <div className="flex flex-col">
                  <p className="font-semibold">
                    For any questions or assistance, please contact our
                    technical support:
                  </p>
                  <div className="flex flex-wrap items-center gap-6 pt-1">
                    {abortDialogData?.techData?.technicalSupportName && (
                      <div className="flex items-center gap-2">
                        <i className="pi pi-user" />
                        <span className="text-md italic text-gray-500">
                          {abortDialogData?.techData?.technicalSupportName}
                        </span>
                      </div>
                    )}
                    {abortDialogData?.techData?.technicalSupportPhoneNumber && (
                      <div className="flex items-center gap-2">
                        <i className="pi pi-phone text-light-purple" />
                        <span className="text-md italic text-gray-500">
                          {
                            abortDialogData?.techData
                              ?.technicalSupportPhoneNumber
                          }
                        </span>
                      </div>
                    )}
                    {abortDialogData?.techData?.technicalSupportEmail && (
                      <div className="flex items-center gap-2">
                        <i className="pi pi-envelope text-blue-500" />
                        <span className="text-md italic text-gray-500">
                          {abortDialogData?.techData?.technicalSupportEmail}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        </Dialog>

        <div className="flex flex-col gap-5">
          {visibleQuestions?.length > 0 ? (
            visibleQuestions.map((question, index) => (
              <div className="flex flex-col" key={index}>
                <div
                  key={question.label}
                  className="flex w-full flex-col gap-2"
                >
                  <label className="font-semibold text-gray-600">
                    {index + 1}. {question.label}
                    {question?.required && (
                      <span className="ml-1 text-red-500">*</span>
                    )}
                  </label>
                  {question.type === "multipleChoice" && (
                    <div>
                      <Controller
                        name={question.name}
                        control={control}
                        rules={{
                          required:
                            question?.required && "Question is required",
                        }}
                        defaultValue={[]}
                        render={({ field }) => (
                          <MultiSelect
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                              handleInputChange(question, e.value);
                            }}
                            options={question.options.map((option, index) => ({
                              label: option.label,
                              value: option.label,
                              key: `${option.label}-${index}`,
                            }))}
                            id="multipleChoice-question-name"
                            optionLabel="label"
                            display="chip"
                            placeholder="Select answers"
                            maxSelectedLabels={3}
                            className="md:w-20rem w-[40%]"
                          />
                        )}
                      />
                      {formState?.errors &&
                        formState?.errors[question.name] && (
                          <p className="text-sm text-red-500">
                            {formState?.errors[question.name].message}
                          </p>
                        )}
                    </div>
                  )}
                  {question.type === "dropDown" && (
                    <div>
                      <Controller
                        name={question.name}
                        control={control}
                        defaultValue=""
                        rules={{
                          required:
                            question?.required && "Question is required",
                        }}
                        render={({ field }) => (
                          <Dropdown
                            value={field.value}
                            id="dropDown-question-name"
                            placeholder="Select an option"
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              handleInputChange(question, e.target.value);
                            }}
                            options={question.options.map(
                              (option) => option.label,
                            )}
                          />
                        )}
                      />
                      {formState?.errors &&
                        formState?.errors[question.name] && (
                          <p className="text-sm text-red-500">
                            {formState?.errors[question.name].message}
                          </p>
                        )}
                    </div>
                  )}
                  {["number", "decimal"].includes(question.type) && (
                    <div>
                      <Controller
                        name={question.name}
                        control={control}
                        rules={{
                          required:
                            question?.required && "Question is required",
                          min: {
                            value: limitToThreeDecimalPlaces(
                              +question?.constraints?.min,
                            ),
                            message: `Answer cannot be less than ${question?.constraints?.type === "height" ? convertToFeetAndInches(question?.constraints?.min) : limitToThreeDecimalPlaces(+question?.constraints?.min)}`,
                          },
                          max: {
                            value: limitToThreeDecimalPlaces(
                              +question?.constraints?.max,
                            ),
                            message: `Answer cannot be more than ${question?.constraints?.type === "height" ? convertToFeetAndInches(question?.constraints?.max) : limitToThreeDecimalPlaces(+question?.constraints?.max)}`,
                          },
                        }}
                        defaultValue={null}
                        render={({ field }) => {
                          if (question?.constraints?.type === "height") {
                            return (
                              <HeightInput
                                onHeightChange={(value) => {
                                  field.onChange(value);
                                  handleInputChange(question, value);
                                  if (value !== null) {
                                    clearErrors(question.name);
                                  } else {
                                    setError(question.name, {
                                      message: "Question is required",
                                      type: "required",
                                    });
                                  }
                                }}
                                heightValue={
                                  field.value > 0 ? field.value : null
                                }
                              />
                            );
                          } else {
                            return (
                              <InputNumber
                                useGrouping={false}
                                minFractionDigits={
                                  question.type === "number" ? undefined : 2
                                }
                                maxFractionDigits={
                                  question.type === "number" ? undefined : 3
                                }
                                id={field.name}
                                inputRef={field.ref}
                                value={field.value}
                                onBlur={field.onBlur}
                                min={0}
                                maxLength={15}
                                placeholder="Enter a number"
                                onValueChange={(e) => {
                                  field.onChange(e.value);
                                  handleInputChange(question, e.value);
                                }}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                  handleInputChange(question, e.value);
                                  if (e.value !== null) {
                                    clearErrors(question.name);
                                  } else {
                                    setError(question.name, {
                                      message: "Question is required",
                                      type: "required",
                                    });
                                  }
                                }}
                              />
                            );
                          }
                        }}
                      />
                      {formState?.errors &&
                        formState?.errors[question.name] && (
                          <p className="text-sm text-red-500">
                            {formState?.errors[question.name].message}
                          </p>
                        )}
                    </div>
                  )}
                  {question.type === "yesNo" && (
                    <>
                      <div className="flex gap-5 text-gray-500">
                        <label className="cursor-pointer">
                          <Controller
                            name={question.name}
                            control={control}
                            defaultValue=""
                            rules={{
                              required:
                                question?.required && "Question is required",
                            }}
                            render={({ field }) => (
                              <RadioButton
                                type="radio"
                                value="Yes"
                                id="yes-question-name"
                                checked={field.value === "Yes"}
                                onChange={() => {
                                  field.onChange("Yes");
                                  handleInputChange(question, "Yes");
                                }}
                                className="mr-1"
                              />
                            )}
                          />
                          Yes
                        </label>
                        <label className="cursor-pointer">
                          <Controller
                            name={question.name}
                            control={control}
                            defaultValue=""
                            rules={{
                              required:
                                question?.required && "Question is required",
                            }}
                            render={({ field }) => (
                              <RadioButton
                                type="radio"
                                value="No"
                                id="No-question-name"
                                checked={field.value === "No"}
                                onChange={() => {
                                  field.onChange("No");
                                  handleInputChange(question, "No");
                                }}
                                className="mr-1"
                              />
                            )}
                          />
                          No
                        </label>
                      </div>
                      {formState?.errors &&
                        formState?.errors[question.name] && (
                          <p className="text-sm text-red-500">
                            {formState?.errors[question.name].message}
                          </p>
                        )}
                    </>
                  )}
                  {question.description?.htmlValue &&
                    question.description.textValue
                      .trim()
                      .replace(/\\./g, "") && (
                      <div className="card">
                        <Editor
                          value={
                            question.description?.htmlValue
                              ? question.description.htmlValue
                              : null
                          }
                          id="description"
                          readOnly
                          headerTemplate={header}
                          style={{ height: "128px" }}
                        />
                      </div>
                    )}
                </div>
                {/* {visibleQuestions.length - 1 !== index && (
                  <Divider style={{ margin: 0, padding: "0.75rem" }} />
                )} */}
              </div>
            ))
          ) : (
            <p className="text-center text-gray-500">
              There are no questions required at this stage. <br />
              Click 'Next' to proceed with your request.
            </p>
          )}
        </div>
      </form>
      <form
        ref={calculateCostRef}
        onSubmit={(e) => {
          e.preventDefault();
          calculateServiceCost();
        }}
      ></form>
    </>
  );
}

export default RequestService;
