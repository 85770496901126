import { useEffect, useRef, useState } from "react";

import axios from "axios";
import { useSearchParams } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";

const CameraCapture = ({ sessionData }) => {
  const videoRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [facingMode, setFacingMode] = useState("user"); // 'user' for front camera, 'environment' for rear
  const [devices, setDevices] = useState([]);
  const [hasPermission, setHasPermission] = useState(false);

  const [error, setError] = useState(null);
  const [driverLicenseType, setDriverLicensetype] = useState("front"); // getsessionData
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("id");

  const toast = useRef(null);
  const sessionTypes = {
    1: "Driver License",
    2: "Passport",
    4: "Selfie",
  };
  const sessionDescriptions = {
    1: "Make sure the front or back side of your Driver License is fully visible and clear.",
    2: "Ensure your Passport is centered and all details are readable.",
    4: "Please ensure your face is clearly visible and centered in the frame.",
  };

  const getSessionDescription = (typeId) => {
    return (
      sessionDescriptions[typeId] ||
      "Ensure the document is clearly visible before capturing."
    );
  };
  const getSessionType = (typeId) => {
    return sessionTypes[typeId] || null;
  };

  const getDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput",
      );
      setDevices(videoDevices);
      return videoDevices;
    } catch (err) {
      setError("Failed to enumerate devices: " + err.message);
      return [];
    }
  };

  const videoStyle = {
    width: "100%",
    maxWidth: "500px",
    transform: facingMode === "user" ? "scaleX(-1)" : "none",
  };

  useEffect(() => {
    const initializeCamera = async () => {
      try {
        // Request camera permission
        await navigator.mediaDevices.getUserMedia({ video: true });
        setHasPermission(true);

        // Now that we have permission, get the devices
        const videoDevices = await getDevices();

        // If we have devices with labels, we know we have permission
        if (videoDevices.some((device) => device.label)) {
          setHasPermission(true);
        }
      } catch (err) {
        setError("Camera permission denied: " + err.message);
      }
    };

    initializeCamera();
  }, []);

  useEffect(() => {
    if (hasPermission) {
      const startCamera = async () => {
        if (stream) {
          stream.getTracks().forEach((track) => track.stop());
        }

        try {
          const newStream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: facingMode },
          });
          setStream(newStream);
          if (videoRef.current) {
            videoRef.current.srcObject = newStream;
          }
          setError(null);
          // Refresh the device list after starting the camera
          await getDevices();
        } catch (err) {
          setError("Failed to start camera: " + err.message);
        }
      };

      startCamera();
    }

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [hasPermission, facingMode]);

  const switchCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };
  const switchDriverLicenseType = () => {
    setDriverLicensetype((prevMode) =>
      prevMode === "front" ? "back" : "front",
    );
  };

  const captureImage = async () => {
    if (videoRef.current) {
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext("2d");
      if (facingMode === "user") {
        ctx.scale(-1, 1);
        ctx.drawImage(
          videoRef.current,
          -canvas.width,
          0,
          canvas.width,
          canvas.height,
        );
      } else {
        ctx.drawImage(videoRef.current, 0, 0);
      }
      const dataURL = canvas.toDataURL("image/png");

      try {
        await axios.put(
          `/Identifications/SendCaptureIdentityImage/${sessionId}?image`,
          {
            image: dataURL,
            side:
              sessionData.identityTypes === 1 ? driverLicenseType : undefined,
          },
        );
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Photo Sent Successfully, Check Your Desktop",
          life: 4000,
        });
      } catch (error) {
        console.error(error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.message,
          life: 4000,
        });
      }
    }
  };

  return (
    <div className="mx-auto flex max-w-lg flex-col items-center gap-6 rounded-xl border bg-white p-6 shadow-lg">
      <Toast ref={toast} />
      {error && (
        <div className="w-full rounded bg-red-100 p-2 text-center text-red-600">
          {error}
        </div>
      )}
      <div className="flex flex-col items-center gap-2">
        <label className="text-xl font-semibold text-gray-700">
          {getSessionType(sessionData?.identityTypes)}
        </label>
        <p className="text-center text-sm text-gray-500">
          {getSessionDescription(sessionData?.identityTypes)}
        </p>
      </div>
      <div className="flex h-full w-full justify-center overflow-hidden p-2">
        <video
          ref={videoRef}
          autoPlay
          playsInline
          style={videoStyle}
          className="block h-full w-full rounded-lg border"
        />
      </div>
      <div className="flex w-full flex-col items-center gap-4 p-2">
        {devices.length >= 2 && (
          <Button
            onClick={switchCamera}
            icon="pi pi-arrow-right-arrow-left"
            disabled={devices.length < 2}
            className="bg-indigo-500 hover:bg-indigo-600 w-full text-white"
            label="Switch Camera"
          ></Button>
        )}
        {sessionData.identityTypes === 1 && (
          <Button
            onClick={switchDriverLicenseType}
            icon="pi pi-id-card"
            outlined
            className="bg-indigo-500 hover:bg-indigo-600 w-full text-white"
            label="Switch ID Side"
          ></Button>
        )}
        <Button
          onClick={captureImage}
          icon="pi pi-camera"
          severity="success"
          disabled={!hasPermission}
          className="w-full"
          label={
            sessionData.identityTypes === 1
              ? driverLicenseType === "front"
                ? "Capture Front Side of ID"
                : "Capture Back Side of ID"
              : "Capture Image"
          }
        ></Button>
      </div>
    </div>
  );
};

export default CameraCapture;
