import React from "react";

const Footer = () => {
  return (
    <footer className="mx-2 mb-2 rounded-lg bg-white shadow">
      <div className="mx-auto flex w-full max-w-screen-xl flex-col p-4 md:flex-row md:items-center md:justify-between">
        <span className="text-center text-sm text-gray-500 dark:text-gray-400 md:text-left">
          © <span id="currentYear">{new Date().getFullYear()}</span> MedX™.
          All Rights Reserved.
        </span>
        <ul className="mt-3 flex flex-col items-center text-center text-sm font-medium text-gray-500 dark:text-gray-400 md:mt-0 md:flex-row md:text-left">
          <li className="mb-2 md:mb-0 md:me-6">
            <a
              href="https://acceleratedhcs.com/"
              target="_blank"
              className="hover:underline"
            >
              <span className="pi pi-globe mr-1.5" />
              About
            </a>
          </li>
          <li className="mb-2 md:mb-0 md:me-6">
            <a href="/legal" target="_blank" className="hover:underline">
              <span className="pi pi-list mr-1.5" />
              Legal Notices and Terms
            </a>
          </li>
          <li>
            <a
              href="https://acceleratedhcs.com/#contact"
              target="_blank"
              className="hover:underline"
            >
              <span className="pi pi-phone mr-1.5" />
              Contact Us
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
