import { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import { Divider } from "primereact/divider";
import { Tooltip } from "primereact/tooltip";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from "primereact/multiselect";
import { confirmDialog } from "primereact/confirmdialog";

import GroupForm from "./GroupForm";
import SelectDocuments from "./SelectDocuments";
import SwappedBillableTable from "./SwappedBillableTable";
import useTokenData from "../../../../../hooks/useTokenData";
import { ReusableDataList } from "../../../../../components/Table";
import useHandleResize from "../../../../../hooks/useHandleResize";
import SelectSingleBillableTable from "./SelectSingleBillableTable";
import { HeightInput } from "../../../../../components/formElements";
import { CreateBillableItem } from "../../components/CreateBillableItem";
import useDelayedInputChange from "../../../../../hooks/useDelayedInputChange";
import { editorHeaderTemplate, formatDate } from "../../../../../utils/helpers";

const RuleForm = ({
  rules,
  setRules,
  allRules,
  setAllRules,
  formQuestions,
  billables,
  setBillables,
  mainLocation,
  allQuestions,
  isEditingRule,
  setIsEditingRule,
  questionChosen,
  setQuestionChosen,
  answerChosen,
  setAnswerChosen,
  toast,
  editedQuestionIndex,
  setEditedQuestionIndex,
  standAlone = false,
  setVisible,
  visible,
  selectedCptCodes,
  setSelectedCptCodes,
  currentEditingRules,
  setQuestionandAnswerToEdit,
  quickAddRule = false,
  setQuickAddRule = () => {},
  serviceDocumentsIds = [],
}) => {
  const initialRule = {
    type: "single",
    name: "",
    action: "addCPT",
    ruleCondition: "and",
    locations: [],
    billableItems: [],
    documents: [],
    conditions: [
      {
        question: {
          label: "",
        },
        answer: {
          label: "",
        },
        questionCondition: "equal",
        answerOptions: [],
        numberComparisionCondition: "equal",
        numberComparision: 0,
        numberComparisionRange: { min: 0, max: 0 },
      },
    ],
    defaultUnitsChange: [],
  };
  const [dummySave, setDummySave] = useState(false);
  const initialGroup = {
    name: "",
    type: "group",
    operator: "and",
    action: "addCPT",
    billableItems: [],
    documents: [],
    locations: [],
    defaultUnitsChange: [],
    rules: [
      {
        type: "single",
        name: "Rule-1",
        ruleCondition: "and",
        conditions: [
          {
            question: {
              label: "",
            },
            answer: {
              label: "",
              answerOptions: [],
            },
            questionCondition: "equal",
            numberComparisionCondition: "equal",
            numberComparision: 0,
            numberComparisionRange: { min: 0, max: 0 },
          },
        ],
      },
      {
        type: "single",
        name: "Rule-2",
        ruleCondition: "and",
        conditions: [
          {
            question: {
              label: "",
            },
            answer: {
              label: "",
              answerOptions: [],
            },
            questionCondition: "equal",
            numberComparisionCondition: "equal",
            numberComparision: 0,
            numberComparisionRange: { min: 0, max: 0 },
          },
        ],
      },
    ],
  };
  const [notSavedRules, setNotSavedRules] = useState([]);
  const [billableItemVisible, setBillableItemVisible] = useState(false);
  const [swap, setSwap] = useState(false);
  const [billableToSwapDialogVisible, setBillableToSwapDialogVisible] =
    useState(false);
  const [swappedBillableDialogVisible, setSwappedBillableDialogVisible] =
    useState(false);
  // const [visible, setVisible] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [tableVisible, setTableVisible] = useState(false);
  const [searchValue, handleInputChange, setSearchValue] =
    useDelayedInputChange(refetch, setRefetch);
  const [hcpcsSearchValue, setHcpcsSearchValue] = useState(undefined);
  const [noResults, setNoResults] = useState(false);
  const { entityId } = useTokenData();

  useEffect(() => {
    setFormErrors([]);
  }, [visible]);

  const modTemplate = (rowData, mod) => {
    return rowData[mod] ? (
      <p>{rowData[mod]}</p>
    ) : (
      <p className="border-[1px] border-gray-400"></p>
    );
  };

  const getUsedBillableItems = (index) => {
    const swapAction = allRules.filter(
      (rule, i) => rule.action === "swapCPT" && index !== i,
    );
    const swappedBillableItems = swapAction.length
      ? swapAction.map((item) => item.billableItems[1])
      : [];

    return allRules
      .filter((rule, i) => rule.action === "addCPT" && index !== i)
      .map((rule) => rule.billableItems)
      .flat()
      .concat(selectedCptCodes)
      .concat(swappedBillableItems)
      .map((b) => b.id);
  };

  const accept = () => {
    setVisible(false);
    setRules([]);
    setQuestionandAnswerToEdit({
      answer: "",
      question: "",
    });
    setAnswerChosen([]);
    setRules([]);
    setIsEditingRule(false);
  };
  const reject = () => {};
  const priceTemplate = (rowData) => {
    return (
      <p>
        {rowData.chargeAmount} <span className="text-green-500">$</span>
      </p>
    );
  };
  const deleteTemplate = (rowData, ruleIndex, rule) => {
    const rulesClone = rules.length ? [...rules] : [...allRules];
    const index = [...rulesClone][ruleIndex].billableItems.findIndex(
      (obj) => obj.id == rowData.id,
    );
    return (
      <div className="mt-4" key={ruleIndex}>
        {index !== -1 ? (
          <i
            className="pi pi-times cursor-pointer text-red-400"
            min={1}
            id="delete-template"
            type="number"
            onClick={(e) => {
              const newRules = rules.length ? [...rules] : [...allRules];
              newRules[ruleIndex].billableItems.splice(index, 1);
              rules.length ? setRules(newRules) : setAllRules(newRules);
            }}
          />
        ) : null}
      </div>
    );
  };
  const deleteBillableTemplate = (rowData, ruleIndex, rule) => {
    const rulesClone = rules.length ? [...rules] : [...allRules];
    const index = [...rulesClone][ruleIndex].billableItems.findIndex(
      (obj) => obj.id == rowData.id,
    );
    return (
      <div key={ruleIndex}>
        {
          <Checkbox
            className="cursor-pointer"
            id="delete-billable-item"
            checked={index !== -1}
            type="number"
            onClick={() => {
              const newRules = rules.length ? [...rules] : [...allRules];

              index !== -1
                ? newRules[ruleIndex].billableItems.splice(index, 1)
                : newRules[ruleIndex].billableItems.push(rowData);
              rules.length ? setRules(newRules) : setAllRules(newRules);
            }}
          ></Checkbox>
        }
      </div>
    );
  };

  const differenceByKey = (array1, array2, key) => {
    const set2 = new Set(array2.map((item) => item[key]));
    return array1.filter((item) => !set2.has(item[key]));
  };

  const removeAllExceptBillableItemsTemplate = (rowData, ruleIndex, rule) => {
    const rulesClone = rules.length ? [...rules] : [...allRules];

    const index = rulesClone[ruleIndex].billableItems.findIndex(
      (obj) => obj.id == rowData.id,
    );
    return (
      <div key={ruleIndex}>
        {
          <Checkbox
            className="cursor-pointer"
            id="delete-billable-item"
            checked={index !== -1}
            type="number"
            onClick={() => {
              const newRules = rules.length ? [...rules] : [...allRules];

              index !== -1
                ? newRules[ruleIndex].billableItems.splice(index, 1)
                : newRules[ruleIndex].billableItems.push(rowData);

              rules.length ? setRules(newRules) : setAllRules(newRules);
            }}
          ></Checkbox>
        }
      </div>
    );
  };

  function translatePattern(pattern) {
    // Escape regex special characters except for our wildcard and range characters
    pattern = pattern.replace(/([.+^$(){}|\\])/g, "\\$1");

    // Translate the user-friendly pattern into regex
    pattern = pattern
      .replace(/\*/g, ".*") // * => .*
      .replace(/\?/g, ".") // ? => .
      .replace(/#/g, "[0-9]") // # => [0-9]
      .replace(/\[!([^\]]+)\]/g, "[^$1]") // [!a-z] => [^a-z] for negation
      .replace(/\[([^\]]+)\]/g, "[$1]"); // [a-z] => [a-z]

    try {
      return new RegExp("^" + pattern + "$");
    } catch (error) {
      return null;
    }
  }

  function matchPattern(expression, pattern) {
    const regex = translatePattern(pattern);
    try {
      return regex.test(expression);
    } catch (error) {
      return null;
    }
  }

  const unitsTemplate = (rowData, ruleIndex, rule) => {
    const rulesClone = notSavedRules.length
      ? structuredClone([...notSavedRules])
      : structuredClone([...rules]);
    const index = [...rulesClone][ruleIndex].billableItems.findIndex(
      (obj) => obj.id == rowData.id,
    );
    return (
      <div className="mt-4" key={ruleIndex}>
        {index !== -1 ? (
          <div>
            <InputText
              className={`${notSavedRules[ruleIndex]?.billableItems[index].error ? "p-invalid" : ""} w-16`}
              id="billable-units"
              value={rulesClone[[ruleIndex]].billableItems[index].units}
              min={1}
              tooltipOptions={{ position: "top" }}
              tooltip={notSavedRules[ruleIndex]?.billableItems[index].error}
              keyfilter={/^\d+$/}
              onChange={(e) => {
                const newRules = rulesClone;
                newRules[ruleIndex].billableItems[index] = {
                  ...newRules[ruleIndex]?.billableItems[index],
                  units: Number(e.target.value),
                  error: Number(e.target.value) ? "" : "Value cannot be 0",
                };
                notSavedRules.length
                  ? setNotSavedRules(newRules)
                  : setNotSavedRules(newRules);
              }}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const descriptionWithTooltipTemplate = (rowData) => {
    return (
      <div>
        <Tooltip target=".description" className="max-w-sm" />
        <p
          className="description cursor-help"
          data-pr-position="top"
          data-pr-tooltip={rowData.description}
        >
          {rowData.shortDescription}
        </p>
      </div>
    );
  };
  const changeExistingUnit = (rowData, ruleIndex, rule) => {
    const index = rule.defaultUnitsChange.findIndex(
      (obj) => obj.id == rowData.id,
    );

    return (
      <div className="mt-4" key={ruleIndex}>
        <InputText
          className="ml-2 w-16"
          id="default-billable-units"
          value={rowData.changeInUnit}
          onChange={(e) => {
            const newValue = Number(e.target.value);
            const ruleClone = [...rules];
            if (/^\d*$/.test(newValue)) {
              if (index === -1 && newValue > 0) {
                ruleClone[ruleIndex].defaultUnitsChange.push({
                  ...rowData,
                  changeInUnit: newValue,
                });
              } else {
                if (newValue > 0) {
                  ruleClone[ruleIndex].defaultUnitsChange[index].changeInUnit =
                    newValue;
                } else {
                  ruleClone[ruleIndex].defaultUnitsChange.splice(index, 1);
                }
              }
              rules.length > 0 ? setRules(ruleClone) : setAllRules(ruleClone);
            }
          }}
        />
      </div>
    );
  };
  const questionOperators = [
    { label: "Equal", value: "equal" },
    { label: "Not Equal", value: "notEqual" },
    { label: "Not Answered", value: "notAnswered" },
  ];

  const ruleOperators = [
    { label: "All", value: "and" },
    { label: "Any", value: "or" },
  ];

  const handleChange = (ruleIndex, field, value) => {
    if (rules.length) {
      const newRules = [...rules];
      newRules[ruleIndex] = { ...newRules[ruleIndex], [field]: value };
      setRules(newRules);
    } else {
      const newRules = [...allRules];
      newRules[ruleIndex] = { ...newRules[ruleIndex], [field]: value };
      setAllRules(newRules);
    }
  };
  const handleAddCondition = (ruleIndex) => {
    if (rules.length) {
      const newRules = [...rules];
      newRules[ruleIndex].conditions.push({
        question: { label: "" },
        answer: { label: "" },
        questionCondition: "equal",
        numberComparisionCondition: "equal",
        numberComparision: 0,
        numberComparisionRange: { min: 0, max: 0 },
      });
      setRules(newRules);
    } else {
      const newRules = [...allRules];
      newRules[ruleIndex].conditions.push({
        question: { label: "" },
        answer: { label: "" },
        questionCondition: "equal",
        numberComparisionCondition: "equal",
        numberComparision: 0,
        numberComparisionRange: { min: 0, max: 0 },
      });
      setAllRules(newRules);
    }
  };

  useEffect(() => {
    if (!quickAddRule) return;

    handleAddRule();
    setQuickAddRule(false);
  }, [quickAddRule]);

  const handleAddRule = () => {
    setRules((prevArray) => [
      ...prevArray,
      {
        ...initialRule,
        name: `Rule-${allRules.length ? allRules.length + 1 : rules.length + 1}`,
      },
    ]);
    setVisible(true);
  };

  const handleAddCombination = () => {
    if (!rules.some((rule) => rule.type === "group" && !rule.name.trim())) {
      setRules((prevArray) => [
        ...prevArray,
        { ...initialGroup, name: `Group-${allRules.length + 1}` },
      ]);
    }
    setVisible(true);
  };
  const handleDeleteRule = (index, ruleIndex) => {
    if (rules.length) {
      const newRules = [...rules];
      newRules[ruleIndex].conditions.splice(index, 1);
      setRules(newRules);
    } else {
      const newRules = [...allRules];
      newRules[ruleIndex].conditions.splice(index, 1);
      setAllRules(newRules);
    }
  };

  function validateAllTypesRules(dataArray, errors, firstError, noErrors) {
    if (
      dataArray.length > 1 &&
      dataArray.every((item) => item.type === "group" || item.type === "single")
    ) {
      for (let i = 0; i < dataArray.length; i++) {
        const item = dataArray[i];
        if (item.type === "single") {
          if (!item.name.trim()) {
            errors[i].name = "Rule name is required.";
            noErrors = false;
            !firstError && (firstError = { name: `ruleName${i}` });
            isErrorMessageSet = true;
          }
          if (!item.ruleCondition || !item.ruleCondition.trim()) {
            errors[i].ruleCondition = "Operator is required.";
            noErrors = false;
            !firstError && (firstError = { name: `operator${i}` });
            isErrorMessageSet = true;
          }

          for (let index = 0; index < item.conditions.length; index++) {
            if (
              item.conditions[index].questionCondition === "notAnswered" &&
              item.conditions[index].question.label
            )
              continue;
            if (
              !item.conditions[index].question.label ||
              !item.conditions[index].answer.label
            ) {
              errors[i].conditions = "Conditions are required.";
              noErrors = false;
              !firstError && (firstError = { name: i + "conditions-key" });
              isErrorMessageSet = true;
              break;
            }
          }

          if (
            !item.billableItems[0] &&
            (item.action === "addCPT" ||
              item.action === "removeCPT" ||
              item.action === "removeAllExcept" ||
              item.action === "removeMatchingPattern")
          ) {
            errors[i].billableItems =
              "Please Select At least one billable item to apply this action";
            noErrors = false;
            !firstError && (firstError = { name: `action${i}` });
            isErrorMessageSet = true;
          }

          if (!item.billableItems[1] && item.action === "swapCPT") {
            errors[i].billableItems = "Please Select Billable To Add";
            noErrors = false;
            !firstError && (firstError = { name: `action${i}` });
            isErrorMessageSet = true;
          }
          if (!item.billableItems[0] && item.action === "swapCPT") {
            errors[i].billableItems = "Please Select Billable To Remove";
            noErrors = false;
            !firstError && (firstError = { name: `action${i}` });
            isErrorMessageSet = true;
          }

          if (
            (item.action === "addLocations" ||
              item.action === "removeLocations") &&
            !item.locations.length
          ) {
            errors[i].locations = "At least one location is required.";
            noErrors = false;
            !firstError && (firstError = { name: `action${i}` });
            isErrorMessageSet = true;
          }

          if (
            (item.action === "addDocs" || item.action === "removeDocs") &&
            !item.documents.length
          ) {
            errors[i].documents =
              "Please Select At least one document to apply this action";
            noErrors = false;
            !firstError && (firstError = { name: `action${i}` });
            isErrorMessageSet = true;
          }

          if (
            item.action === "AddUnitsToExisting" ||
            item.action === "removeUnitsFromExisting"
          ) {
            if (!item.defaultUnitsChange.length) {
              errors[i].defaultUnitsChange =
                "Please select default units or change this action";
              noErrors = false;
              !firstError && (firstError = { name: `defaultUnitsChange${i}` });
              isErrorMessageSet = true;
            } else {
              let hasValidChange = false;
              item.defaultUnitsChange.forEach((change, index) => {
                if (change.changeInUnit && change.changeInUnit > 0) {
                  hasValidChange = true;
                }
              });
              if (!hasValidChange) {
                errors[i].defaultUnitsChange = "At least one unit change ";
                noErrors = false;
                !firstError &&
                  (firstError = { name: `defaultUnitsChange${i}` });
                isErrorMessageSet = true;
              }
            }
          }
          if (
            item.action === "abort" &&
            (!item.abortMessage || !item.abortMessage.trim())
          ) {
            errors[i].abortMessage = "Abort message is required.";
            noErrors = false;
            !firstError && (firstError = { name: `abortMessage${i}` });
            isErrorMessageSet = true;
          }
        } else if (item.type === "group") {
          if (!item.name.trim()) {
            errors[i].name = "Group name is required.";
            noErrors = false;
            !firstError && (firstError = { name: `ruleName${i}` });
            isErrorMessageSet = true;
          }
          if (!item.operator || !item.operator.trim()) {
            errors[i].operator = "Operator is required.";
            noErrors = false;
            !firstError && (firstError = { name: `operator${i}` });
            isErrorMessageSet = true;
          }

          if (
            !item.billableItems[0] &&
            (item.action === "addCPT" ||
              item.action === "removeCPT" ||
              item.action === "removeAllExcept" ||
              item.action === "removeMatchingPattern")
          ) {
            errors[i].billableItems =
              "Please Select At least one billable item to apply this action.";
            noErrors = false;
            !firstError && (firstError = { name: `action${i}` });
            isErrorMessageSet = true;
          }

          if (!item.billableItems[1] && item.action === "swapCPT") {
            errors[i].billableItems = "Please Select Billable To Add";
            noErrors = false;
            !firstError && (firstError = { name: `action${i}` });
            isErrorMessageSet = true;
          }
          if (!item.billableItems[0] && item.action === "swapCPT") {
            errors[i].billableItems = "Please Select Billable To Remove";
            noErrors = false;
            !firstError && (firstError = { name: `action${i}` });
            isErrorMessageSet = true;
          }

          if (
            (item.action === "addLocations" ||
              item.action === "removeLocations") &&
            !item.locations.length
          ) {
            errors[i].locations = "At least one location is required.";
            noErrors = false;
            !firstError && (firstError = { name: `action${i}` });
            isErrorMessageSet = true;
          }

          if (
            (item.action === "addDocs" || item.action === "removeDocs") &&
            !item.documents.length
          ) {
            errors[i].documents =
              "Please Select At least one document to apply this action";
            noErrors = false;
            !firstError && (firstError = { name: `action${i}` });
            isErrorMessageSet = true;
          }

          if (
            item.action === "AddUnitsToExisting" ||
            item.action === "removeUnitsFromExisting"
          ) {
            if (!item.defaultUnitsChange.length) {
              errors[i].defaultUnitsChange =
                "Please select default units or change this action.";
              noErrors = false;
              !firstError && (firstError = { name: `defaultUnitsChange${i}` });
              isErrorMessageSet = true;
            } else {
              let hasValidChange = false;
              item.defaultUnitsChange.forEach((change, index) => {
                if (change.changeInUnit && change.changeInUnit > 0) {
                  hasValidChange = true;
                }
              });
              if (!hasValidChange) {
                errors[i].defaultUnitsChange = "At least one unit change ";
                noErrors = false;
                !firstError &&
                  (firstError = { name: `defaultUnitsChange${i}` });
                isErrorMessageSet = true;
              }
            }
          }

          if (
            item.action === "abort" &&
            (!item.abortMessage || !item.abortMessage.trim())
          ) {
            errors[i].abortMessage = "Abort message is required.";
            noErrors = false;
            !firstError && (firstError = { name: `abortMessage${i}` });
            isErrorMessageSet = true;
          }
          if (firstError) {
            const element = document.getElementById(firstError.name);
            if (element) {
              element.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          }

          for (let index = 0; index < item.rules.length; index++) {
            if (!item.rules[index].name) {
              errors[i].rules = errors[i].rules || {};
              errors[i].rules[index] = "Rule name is required.";
              noErrors = false;
              !firstError && (firstError = { name: i + "rules-key" });
              isErrorMessageSet = true;
            } else {
              if (errors[i].rules && errors[i].rules[index]) {
                delete errors[i].rules[index];
                if (Object.keys(errors[i].rules).length === 0) {
                  delete errors[i].rules;
                }
              }
            }
            if (!item.rules[index].ruleCondition) {
              errors[i].ruleConditions = errors[i].ruleConditions || {};
              errors[i].ruleConditions[index] = "Rule condition is required.";
              noErrors = false;
              !firstError && (firstError = { name: i + "rule-conditions-key" });
              isErrorMessageSet = true;
            } else {
              if (errors[i].ruleConditions && errors[i].ruleConditions[index]) {
                delete errors[i].ruleConditions[index];
                if (Object.keys(errors[i].ruleConditions).length === 0) {
                  delete errors[i].ruleConditions;
                }
              }
            }

            for (
              let conditionIndex = 0;
              conditionIndex < item.rules[index].conditions.length;
              conditionIndex++
            ) {
              const condition = item.rules[index].conditions[conditionIndex];
              if (
                condition.questionCondition === "notAnswered" &&
                condition.question.label
              )
                continue;
              if (
                !condition.question.label.trim() ||
                !condition.answer.label.trim()
              ) {
                errors[i].conditions = errors[i].conditions || {};
                errors[i].conditions[index] = "Conditions are required.";
                noErrors = false;
                !firstError && (firstError = { name: `${i}conditions-key` });
                isErrorMessageSet = true;
                break;
              } else {
                if (errors[i].conditions && errors[i].conditions[index]) {
                  delete errors[i].conditions[index];
                  if (Object.keys(errors[i].conditions).length === 0) {
                    delete errors[i].conditions;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  const showDummySave = () => {
    setDummySave(true);
    setTimeout(() => {
      setDummySave(false);
    }, 1500);
  };
  // Function to remove duplicates based on the 'id' property
  const removeDuplicates = (arr) => {
    const seen = new Set();
    return arr.filter((obj) => {
      const duplicate = seen.has(obj.id);
      seen.add(obj.id);
      return !duplicate;
    });
  };
  let isErrorMessageSet;
  function formValidation(dataArray) {
    let errors = [];
    let noErrors = true;
    isErrorMessageSet = false;
    dataArray.forEach((item) => errors.push({}));
    let firstError;
    if (dataArray.length === 0) {
      errors.push("At least one rule is required"); // Push the error message to the array
      isErrorMessageSet = true;
      noErrors = false;
    }
    for (let i = 0; i < dataArray.length; i++) {
      const item = dataArray[i];

      validateAllTypesRules(dataArray, errors, firstError, noErrors);

      if (item.type === "single") {
        if (!item.name.trim()) {
          errors[i].name = "Rule name is required.";
          noErrors = false;
          !firstError && (firstError = { name: `ruleName${i}` });
          isErrorMessageSet = true;
        }
        if (!item.ruleCondition || !item.ruleCondition.trim()) {
          errors[i].ruleCondition = "Operator is required.";
          noErrors = false;
          !firstError && (firstError = { name: `operator${i}` });
          isErrorMessageSet = true;
        }

        for (let index = 0; index < item.conditions.length; index++) {
          const condition = item.conditions[index];

          if (
            condition.questionCondition === "notAnswered" &&
            condition.question.label
          )
            continue;

          if (
            !condition.question.label ||
            (!["number", "decimal"].includes(condition.question.type) &&
              (!condition.answer.label || condition.answer.label.trim() === ""))
          ) {
            errors[i].conditions = "Conditions are required.";
            noErrors = false;
            !firstError && (firstError = { name: i + "conditions-key" });
            isErrorMessageSet = true;
            break;
          }

          if (["number", "decimal"].includes(condition.question.type)) {
            if (!condition.numberComparisionCondition) {
              errors[i].conditions = "Condition is required.";
              noErrors = false;
              !firstError && (firstError = { name: i + "conditions-key" });
              isErrorMessageSet = true;
              break;
            } else if (
              condition.numberComparisionCondition === "between" &&
              (condition.numberComparisionRange.min === null ||
                condition.numberComparisionRange.max === null)
            ) {
              errors[i].conditions = "Conditions answer is required.";
              noErrors = false;
              !firstError && (firstError = { name: i + "conditions-key" });
              isErrorMessageSet = true;
              break;
            } else if (
              condition.numberComparisionCondition !== "between" &&
              condition.numberComparision === null
            ) {
              errors[i].conditions = "Conditions answer is required.";
              noErrors = false;
              !firstError && (firstError = { name: i + "conditions-key" });
              isErrorMessageSet = true;
              break;
            }
          }
        }

        if (
          !item.billableItems[0] &&
          (item.action === "addCPT" ||
            item.action === "removeCPT" ||
            item.action === "removeAllExcept" ||
            item.action === "removeMatchingPattern")
        ) {
          errors[i].billableItems =
            "Please Select At least one billable item to apply this action";
          noErrors = false;
          !firstError && (firstError = { name: `action${i}` });
          isErrorMessageSet = true;
        }

        if (!item.billableItems[1] && item.action === "swapCPT") {
          errors[i].billableItems = "Please Select Billable To Add";
          noErrors = false;
          !firstError && (firstError = { name: `action${i}` });
          isErrorMessageSet = true;
        }
        if (!item.billableItems[0] && item.action === "swapCPT") {
          errors[i].billableItems = "Please Select Billable To Remove";
          noErrors = false;
          !firstError && (firstError = { name: `action${i}` });
          isErrorMessageSet = true;
        }

        if (
          (item.action === "addDocs" || item.action === "removeDocs") &&
          !item.documents.length
        ) {
          errors[i].documents =
            "Please Select At least one document to apply this action";
          noErrors = false;
          !firstError && (firstError = { name: `action${i}` });
          isErrorMessageSet = true;
        }
        if (
          (item.action === "addLocations" ||
            item.action === "removeLocations") &&
          !item.locations.length
        ) {
          errors[i].locations = "At least one location is required.";
          noErrors = false;
          !firstError && (firstError = { name: `action${i}` });
          isErrorMessageSet = true;
        }

        if (
          item.action === "AddUnitsToExisting" ||
          item.action === "removeUnitsFromExisting"
        ) {
          let hasValidChange = false;
          item.defaultUnitsChange.forEach((change, index) => {
            if (change.changeInUnit && change.changeInUnit > 0) {
              hasValidChange = true;
            }
          });
          if (!hasValidChange) {
            errors[i].defaultUnitsChange = "Add at least one unit change ";
            noErrors = false;
            !firstError && (firstError = { name: `defaultUnitsChange${i}` });
            isErrorMessageSet = true;
          }
        }

        if (
          item.action === "abort" &&
          (!item.abortMessage || !item.abortMessage.trim())
        ) {
          errors[i].abortMessage = "Abort message is required.";
          noErrors = false;
          !firstError && (firstError = { name: `abortMessage${i}` });
          isErrorMessageSet = true;
        }
      } else if (item.type === "group") {
        if (!item.name.trim()) {
          errors[i].name = "Group name is required.";
          noErrors = false;
          !firstError && (firstError = { name: `ruleName${i}` });
          isErrorMessageSet = true;
        }
        if (!item.operator || !item.operator.trim()) {
          errors[i].operator = "Operator is required.";
          noErrors = false;
          !firstError && (firstError = { name: `operator${i}` });
          isErrorMessageSet = true;
        }

        if (
          !item.billableItems[0] &&
          (item.action === "addCPT" ||
            item.action === "removeCPT" ||
            item.action === "removeAllExcept" ||
            item.action === "removeMatchingPattern")
        ) {
          errors[i].billableItems =
            "Please Select At least one billable item to apply this action";
          noErrors = false;
          !firstError && (firstError = { name: `action${i}` });
          isErrorMessageSet = true;
        }

        if (!item.billableItems[1] && item.action === "swapCPT") {
          errors[i].billableItems = "Please Select Billable To Add";
          noErrors = false;
          !firstError && (firstError = { name: `action${i}` });
          isErrorMessageSet = true;
        }
        if (!item.billableItems[0] && item.action === "swapCPT") {
          errors[i].billableItems = "Please Select Billable To Remove";
          noErrors = false;
          !firstError && (firstError = { name: `action${i}` });
          isErrorMessageSet = true;
        }

        if (
          (item.action === "addDocs" || item.action === "removeDocs") &&
          !item.documents.length
        ) {
          errors[i].documents =
            "Please Select At least one document to apply this action";
          noErrors = false;
          !firstError && (firstError = { name: `action${i}` });
          isErrorMessageSet = true;
        }
        if (
          (item.action === "addLocations" ||
            item.action === "removeLocations") &&
          !item.locations.length
        ) {
          errors[i].locations = "At least one location is required.";
          noErrors = false;
          !firstError && (firstError = { name: `action${i}` });
          isErrorMessageSet = true;
        }

        if (
          item.action === "AddUnitsToExisting" ||
          item.action === "removeUnitsFromExisting"
        ) {
          if (!item.defaultUnitsChange.length) {
            errors[i].defaultUnitsChange =
              "Please select default units or change this action.";
            noErrors = false;
            !firstError && (firstError = { name: `defaultUnitsChange${i}` });
            isErrorMessageSet = true;
          } else {
            let hasValidChange = false;
            item.defaultUnitsChange.forEach((change, index) => {
              if (change.changeInUnit && change.changeInUnit > 0) {
                hasValidChange = true;
              }
            });
            if (!hasValidChange) {
              errors[i].defaultUnitsChange = "At least one unit change ";
              noErrors = false;
              !firstError && (firstError = { name: `defaultUnitsChange${i}` });
              isErrorMessageSet = true;
            }
          }
        }
        if (
          item.action === "abort" &&
          (!item.abortMessage || !item.abortMessage.trim())
        ) {
          errors[i].abortMessage = "Abort message is required.";
          noErrors = false;
          !firstError && (firstError = { name: `abortMessage${i}` });
          isErrorMessageSet = true;
        }
        if (firstError) {
          const element = document.getElementById(firstError.name);
          if (element) {
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        }

        for (let index = 0; index < item.rules.length; index++) {
          if (!item.rules[index].name) {
            errors[i].rules = errors[i].rules || {};
            errors[i].rules[index] = "Rule name is required.";
            noErrors = false;
            !firstError && (firstError = { name: i + "rules-key" });
            isErrorMessageSet = true;
          } else {
            if (errors[i].rules && errors[i].rules[index]) {
              delete errors[i].rules[index];
              if (Object.keys(errors[i].rules).length === 0) {
                delete errors[i].rules;
              }
            }
          }
          if (!item.rules[index].ruleCondition) {
            errors[i].ruleConditions = errors[i].ruleConditions || {};
            errors[i].ruleConditions[index] = "Rule condition is required.";
            noErrors = false;
            !firstError && (firstError = { name: i + "rule-conditions-key" });
            isErrorMessageSet = true;
          } else {
            if (errors[i].ruleConditions && errors[i].ruleConditions[index]) {
              delete errors[i].ruleConditions[index];
              if (Object.keys(errors[i].ruleConditions).length === 0) {
                delete errors[i].ruleConditions;
              }
            }
          }

          for (
            let conditionIndex = 0;
            conditionIndex < item.rules[index].conditions.length;
            conditionIndex++
          ) {
            const condition = item.rules[index].conditions[conditionIndex];
            if (
              condition.questionCondition === "notAnswered" &&
              condition.question.label
            )
              continue;
            if (
              !condition.question.label ||
              (!["number", "decimal"].includes(condition.question.type) &&
                (!condition.answer.label ||
                  condition.answer.label.trim() === ""))
            ) {
              errors[i].conditions = errors[i].conditions || {};
              errors[i].conditions[index] = "Conditions are required.";
              noErrors = false;
              !firstError && (firstError = { name: `${i}conditions-key` });
              isErrorMessageSet = true;
              break;
            } else {
              if (errors[i].conditions && errors[i].conditions[index]) {
                delete errors[i].conditions[index];
                if (Object.keys(errors[i].conditions).length === 0) {
                  delete errors[i].conditions;
                }
              }
            }
            if (["number", "decimal"].includes(condition.question.type)) {
              if (!condition.numberComparisionCondition) {
                errors[i].conditions = errors[i].conditions || {};
                errors[i].conditions[index] = "Conditions are required.";
                noErrors = false;
                !firstError && (firstError = { name: `${i}conditions-key` });
                isErrorMessageSet = true;
                break;
              } else if (
                condition.numberComparisionCondition === "between" &&
                (condition.numberComparisionRange.min === null ||
                  condition.numberComparisionRange.max === null)
              ) {
                errors[i].conditions = errors[i].conditions || {};
                errors[i].conditions[index] = "Conditions are required.";
                noErrors = false;
                !firstError && (firstError = { name: `${i}conditions-key` });
                isErrorMessageSet = true;
                break;
              } else if (
                condition.numberComparisionCondition !== "between" &&
                condition.numberComparision === null
              ) {
                errors[i].conditions = errors[i].conditions || {};
                errors[i].conditions[index] = "Conditions are required.";
                noErrors = false;
                !firstError && (firstError = { name: `${i}conditions-key` });
                isErrorMessageSet = true;
                break;
              }
            }
          }
        }
      }

      setFormErrors(errors);

      if (Object.keys(errors[i]).length > 0) {
        noErrors = false;
        if (!firstError) {
          firstError = { name: Object.keys(errors[i])[0] };
        }
      }
    }

    if (!noErrors && firstError) {
      const element = document.getElementById(firstError.name);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }

    return noErrors;
  }

  function hasQuestionLabel(rule, label) {
    // Check each item's conditions array
    let res = false;
    rule.conditions.map((condition) => {
      if (condition.question.label == label) {
        res = true;
      }
    });
    return res; // If label not found in any condition, return false
  }
  const getAllServiceDocs = () => {
    const documents = allRules
      .filter((item, index) => {
        if (isEditingRule && index === currentEditingRules) return false;
        return item.action === "addDocs";
      })
      .map((item) => item.documents)
      .flat();

    return [...documents, ...serviceDocumentsIds];
  };

  const getAllBillableItems = (rule) => {
    const billableItems = allRules
      .filter((item, index) => {
        if (isEditingRule && index === currentEditingRules) return false;
        return item.action === "addCPT";
      })
      .map((item) => item.billableItems)
      .flat();

    const allBillableItems = [...billableItems, ...selectedCptCodes];
    if (rule === undefined) return allBillableItems;

    return allBillableItems.map((item) => {
      const index = rule.defaultUnitsChange.findIndex(
        (_item) => _item.id === item.id,
      );
      return {
        ...item,
        changeInUnit:
          index === -1 ? 0 : rule.defaultUnitsChange[index].changeInUnit,
      };
    });
  };

  const showBillableItemInUseToast = () => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: (
        <p id="error-message">
          Changes to this rule will affect a billable item that is already used
          by another rule.
          <strong> Please review your rule settings before saving.</strong>
        </p>
      ),
      life: 4000,
    });
  };

  const mapFunc = (stateForRules) => {
    return stateForRules.map((rule, ruleIndex) => {
      if (rule.type == "group") {
        return (
          <div key={ruleIndex + "group"} className="rounded-lg border">
            <div
              className="flex flex-col bg-slate-50 p-3 md:flex-row md:items-center"
              onClick={(event) => {
                event.preventDefault();
              }}
            >
              <div className="mr-2 font-medium">Group name:</div>
              <InputText
                value={rule.name}
                id="group-name"
                onChange={(e) => {
                  if (rules.length) {
                    const newRules = [...rules];
                    newRules[ruleIndex].name = e.target.value;
                    setRules(newRules);
                  } else {
                    const newRules = [...allRules];
                    newRules[ruleIndex].name = e.target.value;
                    setAllRules(newRules);
                  }
                }}
              />
              {formErrors[ruleIndex]?.name && (
                <p className="text-red-500">{formErrors[ruleIndex].name}</p>
              )}
              <div className="ml-2 mr-2 font-medium not-italic">
                Group Operator:
              </div>
              <Dropdown
                options={ruleOperators}
                value={rule.operator}
                id="group-rule-operators"
                onChange={(e) => {
                  if (rules[ruleIndex].rules.length) {
                    const newRules = [...rules];
                    newRules[ruleIndex].operator = e.target.value;
                    setRules(newRules);
                  } else {
                    const newRules = [...allRules];
                    newRules[ruleIndex].operator = e.target.value;
                    setAllRules(newRules);
                  }
                }}
              />
              {formErrors[ruleIndex]?.operator && (
                <p className="mb-1 mt-1 text-red-500">
                  {formErrors[ruleIndex].operator}
                </p>
              )}
            </div>
            <div className="p-3">
              <GroupForm
                groupRule={structuredClone(rule)}
                groupIndex={ruleIndex}
                allRules={structuredClone(allRules)}
                setAllRules={setAllRules}
                setRules={setRules}
                selectedCptCodes={structuredClone(selectedCptCodes)}
                mainRules={structuredClone(rules)}
                allQuestions={structuredClone(allQuestions)}
                hasQuestionLabel={hasQuestionLabel}
                mainLocation={structuredClone(mainLocation)}
                formErrors={formErrors}
                toast={toast}
                getUsedBillableItems={() =>
                  getUsedBillableItems(
                    isEditingRule ? currentEditingRules : undefined,
                  )
                }
                getAllBillableItems={getAllBillableItems}
                serviceDocumentsIds={serviceDocumentsIds}
                getAllServiceDocs={getAllServiceDocs}
              />
            </div>
          </div>
        );
      }
      return (
        <div key={ruleIndex}>
          <Dialog
            blockScroll
            draggable={false}
            visible={billableToSwapDialogVisible}
            onHide={() => {
              setBillableToSwapDialogVisible(false);
            }}
            header="Choose a billable item to remove"
            className="w-11/12 lg:w-[80vw]"
          >
            <SelectSingleBillableTable
              value={getAllBillableItems()}
              selection={rule.billableItems[0]}
              onSelectionChange={(e) => {
                const newRules = rules.length ? [...rules] : [...allRules];
                newRules[ruleIndex].billableItems[0] = e.value;
                rules.length ? setRules(newRules) : setAllRules(newRules);
                setBillableToSwapDialogVisible(false);
              }}
            />
          </Dialog>
          <Dialog
            blockScroll
            draggable={false}
            visible={swappedBillableDialogVisible}
            onHide={() => {
              setSearchValue("");
              setNotSavedRules([]);
              setSwappedBillableDialogVisible(false);
            }}
            header="Choose a billable item to add"
            className="w-11/12 lg:w-[80vw]"
          >
            <div className="flex flex-col">
              <SelectSingleBillableTable
                link={`/BillableItems/GetAllBillableItems/${entityId}?Filters=${searchValue}`}
                refetch={refetch}
                searchValue={searchValue}
                handleInputChange={handleInputChange}
                emptyMessage={
                  searchValue ? (
                    <p className="text-center">
                      No results found in your practice's configured billable
                      items. Alternatively, you can{" "}
                      <span
                        className="cursor-pointer font-semibold text-light-purple underline"
                        onClick={() => {
                          setNoResults(true);
                          setSwap(true);
                          setSearchValue("");
                          setNotSavedRules([]);
                          setHcpcsSearchValue("");
                          setBillableItemVisible(true);
                          setSwappedBillableDialogVisible(false);
                        }}
                      >
                        Search in National HCPCS Codes{" "}
                      </span>
                      and import a new item into your practice's billable items.
                    </p>
                  ) : (
                    <p className="text-center">
                      You haven't created any billable items yet. You can create
                      a new item by clicking on 'Create New Billable Item'.
                    </p>
                  )
                }
                payload={getUsedBillableItems(
                  isEditingRule ? currentEditingRules : undefined,
                )}
                selection={
                  notSavedRules.length
                    ? notSavedRules[ruleIndex].billableItems[1]
                    : rule.billableItems[1]
                }
                onUnitsChange={(e) => {
                  const newRules = notSavedRules.length
                    ? structuredClone(notSavedRules)
                    : structuredClone(rules);
                  newRules[ruleIndex].billableItems[1].units = e.target.value;
                  setNotSavedRules(newRules);
                }}
                onSelectionChange={(e) => {
                  const newRules = notSavedRules.length
                    ? structuredClone(notSavedRules)
                    : structuredClone(rules);
                  newRules[ruleIndex].billableItems[1] = {
                    ...e.value,
                    units: e.value.defaultUnits,
                  };
                  setNotSavedRules(newRules);
                }}
              />
              <Divider
                layout="horizontal"
                align="center"
                className="w-10/12 self-center"
              >
                <b>OR</b>
              </Divider>
              <div className="flex justify-center">
                <Button
                  id="serviceBillables"
                  label="Create new billable item"
                  type="button"
                  outlined
                  onClick={() => {
                    setSwap(true);
                    setSearchValue("");
                    setNotSavedRules([]);
                    setHcpcsSearchValue("");
                    setBillableItemVisible(true);
                    setSwappedBillableDialogVisible(false);
                  }}
                />
              </div>
              <Button
                className="self-end"
                label="Save"
                onClick={() => {
                  if (notSavedRules[ruleIndex].billableItems[1].units <= 0) {
                    toast.current.show({
                      severity: "error",
                      summary: "Error",
                      detail: "The number of units must be a minimum of 1",
                      life: 4000,
                    });
                    return;
                  }
                  if (notSavedRules.length) {
                    rules.length
                      ? setRules(notSavedRules)
                      : setAllRules(notSavedRules);
                  }
                  setNotSavedRules([]);
                  setSwappedBillableDialogVisible(false);
                }}
              />
            </div>
          </Dialog>
          <Dialog
            blockScroll
            draggable={false}
            visible={billableItemVisible}
            onHide={() => {
              setSwap(false);
              setBillableItemVisible(false);
              setHcpcsSearchValue("");
              setNoResults(false);
            }}
            header="New Billable Item"
            className="w-11/12 lg:w-[80vw]"
          >
            <CreateBillableItem
              collapse={!noResults}
              data={false}
              hcpcsSearchValue={hcpcsSearchValue}
              toast={toast}
              setVisible={setBillableItemVisible}
              addToService={true}
              selectedCptCodes={rule.billableItems}
              setSelectedCptCodes={(rowData) => {
                const newRules = notSavedRules.length
                  ? structuredClone(notSavedRules)
                  : structuredClone(rules);

                if (swap) {
                  try {
                    newRules[ruleIndex].billableItems[1] = {
                      ...rowData[rowData.length - 1],
                      units: rowData[rowData.length - 1].defaultUnits,
                    };

                    setRules(newRules);
                  } catch (error) {
                    console.error(error);
                  }
                  setSwap(false);
                  setBillableItemVisible(false);
                  setTableVisible(false);
                  setSearchValue("");
                  setNotSavedRules([]);
                  return;
                }

                // Add the object to the newRules[ruleIndex].billableItems
                newRules[ruleIndex].billableItems.push({
                  ...rowData[rowData.length - 1],
                  units: rowData[rowData.length - 1].defaultUnits,
                  error: "",
                });
                setBillableItemVisible(false);
                setRules(newRules);
                setTableVisible(false);
                setSearchValue("");
                setNotSavedRules([]);
              }}
            />
          </Dialog>
          <div key={ruleIndex} className="rounded-lg border">
            <div className="flex items-center rounded-lg bg-slate-50 p-3">
              <div>
                <label
                  htmlFor={`ruleName${ruleIndex}`}
                  className="mr-2 font-medium not-italic"
                >
                  Rule Names:
                </label>
                <InputText
                  id={`ruleName${ruleIndex}`}
                  value={rule.name}
                  onChange={(e) =>
                    handleChange(ruleIndex, "name", e.target.value)
                  }
                  className="mr-2 w-1/3 md:w-auto"
                />
                {formErrors[ruleIndex]?.name && (
                  <p className="text-red-500">{formErrors[ruleIndex].name}</p>
                )}
              </div>
            </div>
            <div className="p-3">
              <div
                id={ruleIndex + "conditions-key"}
                key={ruleIndex + "conditions-key"}
                className="mb-4"
              >
                <h3 className="mb-2 text-lg font-semibold">Rule Operator</h3>
                <div className="mb-2 mt-4" key={rule.ruleCondition.id}>
                  <Tooltip target=".custom-target-icon" />

                  <div className="flex w-full justify-center">
                    <div className="flex w-full items-center justify-center rounded-xl bg-light-purple p-2 text-[.7rem] font-semibold text-white sm:text-base md:w-[80%]">
                      If
                      {
                        <Dropdown
                          className="mx-2 flex h-8 w-20 items-center md:w-28"
                          options={ruleOperators}
                          id="single-rule-operators"
                          value={rule.ruleCondition}
                          onChange={(e) => {
                            if (rules.length) {
                              const newRules = [...rules];
                              newRules[ruleIndex].ruleCondition =
                                e.target.value;
                              setRules(newRules);
                            } else {
                              const newRules = [...allRules];
                              newRules[ruleIndex].ruleCondition =
                                e.target.value;
                              setAllRules(newRules);
                            }
                          }}
                        />
                      }
                      Of The Following “IF” Conditions Are Matched
                    </div>
                    {formErrors[ruleIndex]?.ruleCondition && (
                      <p className="text-red-500">
                        {formErrors[ruleIndex].ruleCondition}
                      </p>
                    )}
                  </div>
                </div>
                <div
                  key={rule.name}
                  className="flex flex-col gap-3 rounded-xl border border-slate-200 bg-slate-50 p-2 md:m-5 md:p-8"
                >
                  {rule?.conditions?.map((condition, index) => {
                    return (
                      <div
                        key={index}
                        className="flex flex-col gap-6 lg:grid lg:grid-cols-11"
                      >
                        <div className="col-span-8 rounded-xl border border-l-8 border-slate-200 border-l-light-purple bg-white p-2 shadow-md md:p-4">
                          <div className="font-bold">Condition {index + 1}</div>
                          <div className="grid gap-2 p-3">
                            <div className="grid grid-cols-1 items-center gap-2 md:row-span-1 md:grid-cols-6">
                              <label
                                className="col-span-1 font-semibold"
                                htmlFor={`question${ruleIndex}-${ruleIndex}`}
                              >
                                IF
                              </label>
                              <Dropdown
                                name={`question${ruleIndex}-${ruleIndex}`}
                                id={`question${ruleIndex}-${ruleIndex}`}
                                value={condition.question}
                                placeholder={
                                  condition.question.label ||
                                  "Select A Question"
                                }
                                dataKey="label"
                                optionLabel="label"
                                options={allQuestions}
                                onChange={(e) => {
                                  condition.numberComparision = null;
                                  condition.numberComparisionRange = {
                                    min: null,
                                    max: null,
                                  };
                                  showDummySave();
                                  if (rules.length) {
                                    const newr = [...rules];
                                    newr[ruleIndex].conditions[index].question =
                                      e.value;
                                    setRules(newr);
                                    newr[ruleIndex].conditions[
                                      index
                                    ].answerOptions = e.value.options;
                                    newr[ruleIndex].conditions[
                                      index
                                    ].answer.label = "";
                                    setRules(newr);
                                  } else {
                                    const newr = [...allRules];
                                    newr[ruleIndex].conditions[index].question =
                                      e.value;
                                    setAllRules(newr);
                                    newr[ruleIndex].conditions[
                                      index
                                    ].answerOptions = e.value.options;
                                    newr[ruleIndex].conditions[
                                      index
                                    ].answer.label = "";
                                    setAllRules(newr);
                                  }
                                }}
                                className="col-span-5 rounded-none"
                              />
                            </div>
                            <div className="row-span-1 grid grid-cols-1 items-center gap-2 md:grid-cols-6">
                              <label
                                className="col-span-1 font-semibold"
                                htmlFor={`question${ruleIndex}-${ruleIndex}`}
                              >
                                State
                              </label>
                              <Dropdown
                                className="col-span-5 rounded-none"
                                id="condition-state"
                                value={condition.questionCondition}
                                options={questionOperators}
                                onChange={(e) => {
                                  showDummySave();
                                  if (rules.length) {
                                    const newRules = [...rules];
                                    newRules[ruleIndex].conditions[
                                      index
                                    ].questionCondition = e.target.value;
                                    setRules(newRules);
                                  } else {
                                    const newRules = [...allRules];
                                    newRules[ruleIndex].conditions[
                                      index
                                    ].questionCondition = e.target.value;
                                    setAllRules(newRules);
                                  }
                                }}
                              />
                            </div>
                            {condition.questionCondition !== "notAnswered" && (
                              <div className="row-span-1 grid grid-cols-1 items-center gap-2 md:grid-cols-6">
                                <label
                                  className="col-span-1 font-semibold"
                                  htmlFor={`question${ruleIndex}-${ruleIndex}`}
                                >
                                  Value
                                </label>

                                <div className="col-span-5 flex flex-col gap-2">
                                  {["number", "decimal"].includes(
                                    condition.question.type,
                                  ) ? (
                                    <>
                                      <Dropdown
                                        placeholder="Comparison operators"
                                        id="condition-value"
                                        options={[
                                          {
                                            label: "Equal",
                                            value: "equal",
                                          },
                                          {
                                            label: "Bigger than",
                                            value: "biggerNumber",
                                          },
                                          {
                                            label: "Smaller than",
                                            value: "smallerNumber",
                                          },
                                          {
                                            label: "Bigger than or equal",
                                            value: "biggerThanOrEqual",
                                          },
                                          {
                                            label: "Smaller than or equal",
                                            value: "smallerThanOrEqual",
                                          },
                                          {
                                            label: "Between",
                                            value: "between",
                                          },
                                        ]}
                                        value={
                                          condition.numberComparisionCondition
                                        }
                                        onChange={(e) => {
                                          showDummySave();
                                          if (rules.length) {
                                            const newRules = [...rules];
                                            newRules[ruleIndex].conditions[
                                              index
                                            ].numberComparisionCondition =
                                              e.target.value;
                                            setRules(newRules);
                                          } else {
                                            const newRules = [...allRules];
                                            newRules[ruleIndex].conditions[
                                              index
                                            ].numberComparisionCondition =
                                              e.target.value;
                                            setAllRules(newRules);
                                          }
                                        }}
                                        className="w-full rounded-none"
                                      />
                                      {(() => {
                                        if (
                                          condition?.question?.numerictype !==
                                            "height" &&
                                          allQuestions.find(
                                            (q) =>
                                              q.label ===
                                              condition.question.label,
                                          ).constraints?.type !== "height"
                                        ) {
                                          if (
                                            condition.numberComparisionCondition ==
                                            "between"
                                          ) {
                                            return (
                                              <div className="grid grid-cols-2 gap-2">
                                                <InputNumber
                                                  useGrouping={false}
                                                  minFractionDigits={
                                                    condition.question.type ===
                                                    "number"
                                                      ? undefined
                                                      : 2
                                                  }
                                                  maxFractionDigits={
                                                    condition.question.type ===
                                                    "number"
                                                      ? undefined
                                                      : 3
                                                  }
                                                  value={
                                                    condition
                                                      .numberComparisionRange
                                                      .min
                                                  }
                                                  className="col-span-1"
                                                  id="condition-number-comparison-between"
                                                  onChange={(e) => {
                                                    showDummySave();
                                                    if (rules.length) {
                                                      const newRules = [
                                                        ...rules,
                                                      ];
                                                      newRules[
                                                        ruleIndex
                                                      ].conditions[
                                                        index
                                                      ].numberComparisionRange.min =
                                                        e.value;
                                                      setRules(newRules);
                                                    } else {
                                                      const newRules = [
                                                        ...allRules,
                                                      ];
                                                      newRules[
                                                        ruleIndex
                                                      ].conditions[
                                                        index
                                                      ].numberComparisionRange.min =
                                                        e.value;
                                                      setAllRules(newRules);
                                                    }
                                                  }}
                                                />
                                                <InputNumber
                                                  useGrouping={false}
                                                  minFractionDigits={
                                                    condition.question.type ===
                                                    "number"
                                                      ? undefined
                                                      : 2
                                                  }
                                                  maxFractionDigits={
                                                    condition.question.type ===
                                                    "number"
                                                      ? undefined
                                                      : 3
                                                  }
                                                  value={
                                                    condition
                                                      .numberComparisionRange
                                                      .max
                                                  }
                                                  id="condition-number-comparison-range"
                                                  className="col-span-1"
                                                  onChange={(e) => {
                                                    showDummySave();
                                                    if (rules.length) {
                                                      const newRules = [
                                                        ...rules,
                                                      ];
                                                      newRules[
                                                        ruleIndex
                                                      ].conditions[
                                                        index
                                                      ].numberComparisionRange.max =
                                                        e.value;
                                                      setRules(newRules);
                                                    } else {
                                                      const newRules = [
                                                        ...allRules,
                                                      ];
                                                      newRules[
                                                        ruleIndex
                                                      ].conditions[
                                                        index
                                                      ].numberComparisionRange.max =
                                                        e.value;
                                                      setAllRules(newRules);
                                                    }
                                                  }}
                                                />
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <InputNumber
                                                useGrouping={false}
                                                minFractionDigits={
                                                  condition.question.type ===
                                                  "number"
                                                    ? undefined
                                                    : 2
                                                }
                                                maxFractionDigits={
                                                  condition.question.type ===
                                                  "number"
                                                    ? undefined
                                                    : 3
                                                }
                                                placeholder="condition number"
                                                value={
                                                  condition.numberComparision
                                                }
                                                id="condition-number-comparison"
                                                className="w-1/2"
                                                onChange={(e) => {
                                                  showDummySave();
                                                  if (rules.length) {
                                                    const newRules = [...rules];
                                                    newRules[
                                                      ruleIndex
                                                    ].conditions[
                                                      index
                                                    ].numberComparision =
                                                      e.value;
                                                    setRules(newRules);
                                                  } else {
                                                    const newRules = [
                                                      ...allRules,
                                                    ];
                                                    newRules[
                                                      ruleIndex
                                                    ].conditions[
                                                      index
                                                    ].numberComparision =
                                                      e.value;
                                                    setAllRules(newRules);
                                                  }
                                                }}
                                              />
                                            );
                                          }
                                        } else if (
                                          condition.question.numerictype ===
                                            "height" ||
                                          allQuestions.find(
                                            (q) =>
                                              q.label ===
                                              condition.question.label,
                                          ).constraints?.type === "height"
                                        ) {
                                          if (
                                            condition.numberComparisionCondition ==
                                            "between"
                                          ) {
                                            return (
                                              <div className="grid grid-cols-2 gap-2">
                                                <HeightInput
                                                  onHeightChange={(value) => {
                                                    showDummySave();
                                                    if (rules.length) {
                                                      const newRules = [
                                                        ...rules,
                                                      ];
                                                      newRules[
                                                        ruleIndex
                                                      ].conditions[
                                                        index
                                                      ].numberComparisionRange.min =
                                                        value;
                                                      setRules(newRules);
                                                    } else {
                                                      const newRules = [
                                                        ...allRules,
                                                      ];
                                                      newRules[
                                                        ruleIndex
                                                      ].conditions[
                                                        index
                                                      ].numberComparisionRange.min =
                                                        value;
                                                      setAllRules(newRules);
                                                    }
                                                  }}
                                                  heightValue={
                                                    condition
                                                      .numberComparisionRange
                                                      .min > 0
                                                      ? condition
                                                          .numberComparisionRange
                                                          .min
                                                      : null
                                                  }
                                                />
                                                <HeightInput
                                                  onHeightChange={(value) => {
                                                    showDummySave();
                                                    if (rules.length) {
                                                      const newRules = [
                                                        ...rules,
                                                      ];
                                                      newRules[
                                                        ruleIndex
                                                      ].conditions[
                                                        index
                                                      ].numberComparisionRange.max =
                                                        value;
                                                      setRules(newRules);
                                                    } else {
                                                      const newRules = [
                                                        ...allRules,
                                                      ];
                                                      newRules[
                                                        ruleIndex
                                                      ].conditions[
                                                        index
                                                      ].numberComparisionRange.max =
                                                        value;
                                                      setAllRules(newRules);
                                                    }
                                                  }}
                                                  heightValue={
                                                    condition
                                                      .numberComparisionRange
                                                      .max > 0
                                                      ? condition
                                                          .numberComparisionRange
                                                          .max
                                                      : null
                                                  }
                                                />
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <HeightInput
                                                onHeightChange={(value) => {
                                                  showDummySave();
                                                  if (rules.length) {
                                                    const newRules = [...rules];
                                                    newRules[
                                                      ruleIndex
                                                    ].conditions[
                                                      index
                                                    ].numberComparision = value;
                                                    setRules(newRules);
                                                  } else {
                                                    const newRules = [
                                                      ...allRules,
                                                    ];
                                                    newRules[
                                                      ruleIndex
                                                    ].conditions[
                                                      index
                                                    ].numberComparision = value;
                                                    setAllRules(newRules);
                                                  }
                                                }}
                                                heightValue={
                                                  condition.numberComparision >
                                                  0
                                                    ? condition.numberComparision
                                                    : null
                                                }
                                              />
                                            );
                                          }
                                        }
                                      })()}

                                      {
                                        <div className="flex items-center gap-4">
                                          {allQuestions.find(
                                            (q) =>
                                              q.label ===
                                              condition?.question?.label,
                                          )?.constraints?.min && (
                                            <p className="flex items-center gap-1">
                                              <i className="pi pi-info-circle"></i>
                                              Min:{" "}
                                              {(() => {
                                                const question =
                                                  allQuestions.find(
                                                    (q) =>
                                                      q.label ===
                                                      condition?.question
                                                        ?.label,
                                                  );
                                                if (
                                                  question?.constraints
                                                    ?.type === "height"
                                                ) {
                                                  const totalFeet = Math.floor(
                                                    question.constraints.min,
                                                  );
                                                  const totalInches =
                                                    Math.round(
                                                      (question.constraints
                                                        .min -
                                                        totalFeet) *
                                                        12,
                                                    );
                                                  return `${totalFeet} (ft) ${totalInches} (in)`;
                                                }
                                                return question?.constraints
                                                  ?.min;
                                              })()}
                                            </p>
                                          )}
                                          {allQuestions.find(
                                            (q) =>
                                              q.label ===
                                              condition?.question?.label,
                                          )?.constraints?.max && (
                                            <p className="flex items-center gap-1">
                                              <i className="pi pi-info-circle"></i>
                                              Max:{" "}
                                              {(() => {
                                                const question =
                                                  allQuestions.find(
                                                    (q) =>
                                                      q.label ===
                                                      condition?.question
                                                        ?.label,
                                                  );
                                                if (
                                                  question?.constraints
                                                    ?.type === "height"
                                                ) {
                                                  const totalFeet = Math.floor(
                                                    question.constraints.max,
                                                  );
                                                  const totalInches =
                                                    Math.round(
                                                      (question.constraints
                                                        .max -
                                                        totalFeet) *
                                                        12,
                                                    );
                                                  return `${totalFeet} (ft) ${totalInches} (in)`;
                                                }
                                                return question?.constraints
                                                  ?.max;
                                              })()}
                                            </p>
                                          )}
                                        </div>
                                      }
                                    </>
                                  ) : (
                                    <Dropdown
                                      disabled={!condition.question.label}
                                      id={`answer${ruleIndex}`}
                                      value={condition.answer.label}
                                      options={condition.answerOptions}
                                      onChange={(e) => {
                                        showDummySave();
                                        if (rules.length) {
                                          const newr = [...rules];
                                          newr[ruleIndex].conditions[
                                            index
                                          ].answer.label = e.value.label;
                                          setRules(newr);
                                        } else {
                                          const newr = [...allRules];
                                          newr[ruleIndex].conditions[
                                            index
                                          ].answer.label = e.value.label;
                                          setAllRules(newr);
                                        }
                                      }}
                                      className="w-full rounded-none"
                                      placeholder={condition.answer.label}
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-span-3 flex items-center gap-4 md:pl-6">
                          {rule?.conditions?.length > 1 ? (
                            <Button
                              severity="danger"
                              id="button-delete-conditions"
                              icon="pi pi-trash color-red"
                              onClick={() => handleDeleteRule(index, ruleIndex)}
                            />
                          ) : null}
                          {index === rule?.conditions?.length - 1 ? (
                            <Button
                              label={
                                rule?.conditions?.length - 1
                                  ? ""
                                  : "Add new condition"
                              }
                              className="m-auto text-sm md:m-0 md:text-base"
                              id="button-add-conditions"
                              icon={"pi pi-plus"}
                              onClick={() => handleAddCondition(ruleIndex)}
                            />
                          ) : null}
                        </div>
                      </div>
                    );
                  })}

                  {dummySave ? (
                    <span className="ml-2 text-green-600">
                      Changes Saved Successfully!
                    </span>
                  ) : null}

                  <span className="align-center mr-2 h-full text-xl">
                    {rules.ruleCondition}
                  </span>
                  {formErrors[ruleIndex]?.conditions && (
                    <p className="text-red-500">
                      {formErrors[ruleIndex]?.conditions}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex w-full justify-center">
                <div className="flex w-full flex-col items-center justify-center rounded-xl bg-light-purple p-2 font-semibold text-white md:w-[80%] md:flex-row md:text-base">
                  Apply This Action
                  <Dropdown
                    id={`action${ruleIndex}`}
                    value={rule.action}
                    options={[
                      { label: "Abort", value: "abort" },
                      { label: "Add Billable items", value: "addCPT" },
                      { label: "Add Documents", value: "addDocs" },
                      { label: "Add locations", value: "addLocations" },
                      {
                        label: "Add units to existing billable items",
                        value: "AddUnitsToExisting",
                      },
                      {
                        label: "Remove All Except",
                        value: "removeAllExcept",
                      },
                      {
                        label: "Remove Any Matching Pattern",
                        value: "removeMatchingPattern",
                      },
                      { label: "Remove Billable items", value: "removeCPT" },
                      { label: "Remove Documents", value: "removeDocs" },
                      { label: "Remove Locations", value: "removeLocations" },
                      {
                        label: "Remove units from existing billable items",
                        value: "removeUnitsFromExisting",
                      },
                      {
                        label: "Replace billable item",
                        value: "swapCPT",
                      },
                    ]}
                    onChange={(e) => {
                      const cloneR = rules;
                      cloneR[ruleIndex].defaultUnitsChange = [];
                      cloneR[ruleIndex].billableItems = [];
                      cloneR[ruleIndex].documents = [];
                      cloneR[ruleIndex].billableItemstoRemoveFrom = [];
                      cloneR[ruleIndex].locations = [];

                      rules.length > 0 ? setRules(cloneR) : setAllRules(cloneR);

                      handleChange(ruleIndex, "action", e.value);
                    }}
                    className="mx-2 flex h-8 items-center md:w-52"
                  />
                </div>
              </div>
              <div className="m-auto flex flex-col gap-3 rounded-xl bg-white md:m-5 md:border md:border-slate-200 md:p-8">
                {formErrors[ruleIndex]?.billableItems && (
                  <p className="mt-1 text-red-500">
                    {formErrors[ruleIndex].billableItems}
                  </p>
                )}
                {formErrors[ruleIndex]?.documents && (
                  <p className="mt-1 text-red-500">
                    {formErrors[ruleIndex].documents}
                  </p>
                )}
                {formErrors[ruleIndex]?.locations && (
                  <p className="mt-1 text-red-500">
                    {formErrors[ruleIndex].locations}
                  </p>
                )}
                {formErrors[ruleIndex]?.abortMessage && (
                  <p className="mt-1 text-red-500">
                    {formErrors[ruleIndex].abortMessage}
                  </p>
                )}
                {formErrors[ruleIndex]?.defaultUnitsChange && (
                  <p className="mt-1 text-red-500">
                    {formErrors[ruleIndex].defaultUnitsChange}
                  </p>
                )}

                <div className="mb-4 items-center">
                  {rule.action == "abort" ? (
                    <div className="flex flex-col gap-2">
                      <div className="font-semibold">
                        Add Message to show on aborting
                      </div>
                      <Editor
                        id="add-abort-message"
                        headerTemplate={editorHeaderTemplate}
                        value={
                          rules.length
                            ? rules[ruleIndex].abortMessage
                            : allRules[ruleIndex].abortMessage
                        }
                        onTextChange={(e) => {
                          const newValue = e.htmlValue;
                          if (rules.length) {
                            const newRules = [...rules];
                            newRules[ruleIndex].abortMessage = newValue;
                            setRules(newRules);
                          } else {
                            const newRules = [...allRules];
                            newRules[ruleIndex].abortMessage = newValue;
                            setAllRules(newRules);
                          }
                        }}
                        className="w-full"
                        style={{ height: "164px" }}
                      />
                    </div>
                  ) : null}
                  {rule.action == "addCPT" ? (
                    <div className="flex flex-col gap-2">
                      <label className="font-semibold">
                        Current Billable Items
                      </label>
                      {rule.billableItems && (
                        <DataTable
                          className="rounded-lg border-2 border-gray-200"
                          emptyMessage={
                            <p className="text-center">
                              Currently, there are no billable items selected.
                            </p>
                          }
                          value={rule.billableItems}
                        >
                          {[
                            { name: "HCPCS", value: "hcpcs" },
                            {
                              name: "Description",
                              template: descriptionWithTooltipTemplate,
                            },
                            {
                              name: "Mod 1",
                              template: (e) => modTemplate(e, "mod1"),
                              header: "Mod 1",
                            },
                            {
                              name: "Mod 2",
                              template: (e) => modTemplate(e, "mod2"),
                              header: "Mod 2",
                            },
                            {
                              name: "Mod 3",
                              template: (e) => modTemplate(e, "mod3"),
                              header: "Mod 3",
                            },
                            {
                              name: "Mod 4",
                              template: (e) => modTemplate(e, "mod4"),
                              header: "Mod 4",
                            },
                            {
                              name: "Price per unit",
                              template: priceTemplate,
                              header: "Price per unit",
                            },
                            {
                              name: "Units",
                              value: "units",
                              header: "Units",
                            },
                            {
                              name: "Remove Billable items",
                              template: (e) =>
                                deleteTemplate(e, ruleIndex, rule),
                              header: "Remove Billable items",
                            },
                          ].map((column) => (
                            <Column
                              key={column.name}
                              header={column.name}
                              align="center"
                              alignHeader="center"
                              style={column.style ? column.style : null}
                              body={(rowData) => {
                                if (
                                  column.name.toLowerCase().includes("date")
                                ) {
                                  return (
                                    rowData[column.value] &&
                                    formatDate(rowData[column.value])
                                  );
                                } else if (
                                  column.template &&
                                  typeof column.template === "function"
                                ) {
                                  return column.template(rowData);
                                } else {
                                  return rowData[column.value];
                                }
                              }}
                            />
                          ))}
                        </DataTable>
                      )}
                      <div>
                        <Button
                          className="mt-4"
                          id="button-add-billable-item"
                          label={
                            rule.billableItems.length
                              ? "Add and edit current billable items"
                              : "Add billable items"
                          }
                          onClick={() => {
                            setTableVisible(true);
                          }}
                          icon="pi pi-plus"
                        />
                      </div>
                      <Dialog
                        blockScroll
                        draggable={false}
                        className="w-11/12 lg:w-[70vw]"
                        header="Attach Billable Items"
                        visible={tableVisible}
                        onHide={() => {
                          setNotSavedRules([]);
                          setTableVisible(false);
                          setSearchValue("");
                        }}
                      >
                        <div className="flex flex-col p-4">
                          <div className="flex flex-col gap-2">
                            <div className="overflow-hidden rounded-xl border border-gray-300">
                              <ReusableDataList
                                scrollable={true}
                                scrollHeight="264px"
                                emptyMessage={
                                  searchValue ? (
                                    <p className="text-center">
                                      No results found in your practice's
                                      configured billable items. Alternatively,
                                      you can{" "}
                                      <span
                                        className="cursor-pointer font-semibold text-light-purple underline"
                                        onClick={() => {
                                          setNoResults(true);
                                          setHcpcsSearchValue(searchValue);
                                          setBillableItemVisible(true);
                                          setTableVisible(false);
                                          setSearchValue("");
                                        }}
                                      >
                                        Search in National HCPCS Codes{" "}
                                      </span>
                                      and import a new item into your practice's
                                      billable items.
                                    </p>
                                  ) : (
                                    <p className="text-center">
                                      You haven't created any billable items
                                      yet. You can create a new item by clicking
                                      on 'Create New Billable Item'.
                                    </p>
                                  )
                                }
                                searchTitle="Search HCPCS, Description"
                                fetchMethod="put"
                                payload={getUsedBillableItems(
                                  isEditingRule
                                    ? currentEditingRules
                                    : undefined,
                                )}
                                dataLink={`/BillableItems/GetAllBillableItems/${entityId}?Filters=${searchValue}`}
                                sendSearchData={handleInputChange}
                                searchValue={searchValue}
                                selectedRows={rules[ruleIndex].billableItems}
                                columns={[
                                  { name: "HCPCS", value: "hcpcs" },
                                  { name: "Description", value: "description" },
                                ]}
                                actionTemplates={[
                                  {
                                    template: (e) => modTemplate(e, "mod1"),
                                    header: "Mod 1",
                                  },
                                  {
                                    template: (e) => modTemplate(e, "mod2"),
                                    header: "Mod 2",
                                  },
                                  {
                                    template: (e) => modTemplate(e, "mod3"),
                                    header: "Mod 3",
                                  },
                                  {
                                    template: (e) => modTemplate(e, "mod4"),
                                    header: "Mod 4",
                                  },
                                  {
                                    template: priceTemplate,
                                    header: "Price per unit",
                                  },
                                  {
                                    template: (e) =>
                                      unitsTemplate(e, ruleIndex, rule),
                                    header: "Units",
                                  },
                                ]}
                                selectionRowFunction={true}
                                onSelectionChange={(rowData) => {
                                  const newRules = notSavedRules.length
                                    ? structuredClone(notSavedRules)
                                    : structuredClone(rules);
                                  const index = newRules[
                                    ruleIndex
                                  ].billableItems.findIndex(
                                    (obj) => obj.id === rowData.data.id,
                                  );
                                  // If the object exists, remove it; otherwise, add it to the array
                                  if (index !== -1) {
                                    // Remove the object from the array using splice
                                    newRules[ruleIndex].billableItems.splice(
                                      index,
                                      1,
                                    );
                                  } else {
                                    // Add the object to the newRules[ruleIndex].billableItems
                                    newRules[ruleIndex].billableItems.push({
                                      ...rowData.data,
                                      units: rowData.data.defaultUnits,
                                      error: "",
                                    });
                                  }
                                  setNotSavedRules(newRules);
                                }}
                                refetch={refetch}
                              />
                            </div>
                          </div>
                          <Divider
                            layout="horizontal"
                            align="center"
                            className="w-10/12 self-center"
                          >
                            <b>OR</b>
                          </Divider>
                          <div className="flex justify-center">
                            <Button
                              id="serviceBillables"
                              label="Create new billable item"
                              type="button"
                              outlined
                              onClick={() => {
                                setBillableItemVisible(true);
                                setHcpcsSearchValue("");
                                setTableVisible(false);
                                setSearchValue("");
                              }}
                            />
                          </div>

                          <div className="mt-2 text-end">
                            <Button
                              label="Save"
                              id="button-save-billable-item"
                              onClick={() => {
                                const hasErrors = notSavedRules[
                                  ruleIndex
                                ]?.billableItems?.some(
                                  (item) => item?.error && item.error !== "",
                                );
                                if (hasErrors === undefined) {
                                  setTableVisible(false);
                                  return;
                                }
                                if (!hasErrors) {
                                  rules.length
                                    ? setRules(notSavedRules)
                                    : setAllRules(notSavedRules);
                                  setTableVisible(false);
                                  setSearchValue("");
                                  setNotSavedRules([]);
                                } else {
                                  return;
                                }
                              }}
                            />
                          </div>
                        </div>
                      </Dialog>
                    </div>
                  ) : null}
                  {rule.action == "removeCPT" ? (
                    <div className="flex flex-col gap-4">
                      <div>
                        <DataTable
                          value={getAllBillableItems()}
                          className="rounded-lg border-2 border-gray-200"
                          emptyMessage={
                            <p className="text-center">
                              Currently, there are no billable items selected.
                            </p>
                          }
                        >
                          {[
                            {
                              name: "Remove from Billable items",
                              template: (e) =>
                                deleteBillableTemplate(e, ruleIndex, rule),
                              header: "Remove from Billable items",
                            },
                            { name: "HCPCS", value: "hcpcs" },
                            {
                              name: "Description",
                              template: descriptionWithTooltipTemplate,
                            },
                            {
                              name: "Mod 1",
                              template: (e) => modTemplate(e, "mod1"),
                              header: "Mod 1",
                            },
                            {
                              name: "Mod 2",
                              template: (e) => modTemplate(e, "mod2"),
                              header: "Mod 2",
                            },
                            {
                              name: "Mod 3",
                              template: (e) => modTemplate(e, "mod3"),
                              header: "Mod 3",
                            },
                            {
                              name: "Mod 4",
                              template: (e) => modTemplate(e, "mod4"),
                              header: "Mod 4",
                            },
                            {
                              name: "Price per unit",
                              template: priceTemplate,
                              header: "Price per unit",
                            },
                          ].map((column) => (
                            <Column
                              key={column.name}
                              header={column.name}
                              align="center"
                              alignHeader="center"
                              style={column.style ? column.style : null}
                              body={(rowData) => {
                                if (
                                  column.name.toLowerCase().includes("date")
                                ) {
                                  return (
                                    rowData[column.value] &&
                                    formatDate(rowData[column.value])
                                  );
                                } else if (
                                  column.template &&
                                  typeof column.template === "function"
                                ) {
                                  return column.template(rowData);
                                } else {
                                  return rowData[column.value];
                                }
                              }}
                            />
                          ))}
                        </DataTable>
                      </div>
                      <div className="flex flex-col gap-2">
                        <div className="font-semibold">
                          Billable Items that will be removed
                        </div>
                        <DataTable
                          value={rule.billableItems}
                          className="rounded-lg border-2 border-gray-200"
                          emptyMessage={
                            <p className="text-center">
                              Currently, there are no billable items selected.
                            </p>
                          }
                        >
                          {[
                            { name: "HCPCS", value: "hcpcs" },
                            {
                              name: "Description",
                              template: descriptionWithTooltipTemplate,
                            },
                            {
                              name: "Mod 1",
                              template: (e) => modTemplate(e, "mod1"),
                              header: "Mod 1",
                            },
                            {
                              name: "Mod 2",
                              template: (e) => modTemplate(e, "mod2"),
                              header: "Mod 2",
                            },
                            {
                              name: "Mod 3",
                              template: (e) => modTemplate(e, "mod3"),
                              header: "Mod 3",
                            },
                            {
                              name: "Mod 4",
                              template: (e) => modTemplate(e, "mod4"),
                              header: "Mod 4",
                            },
                            {
                              name: "Price per unit",
                              template: priceTemplate,
                              header: "Price per unit",
                            },
                          ].map((column) => (
                            <Column
                              key={column.name}
                              header={column.name}
                              align="center"
                              alignHeader="center"
                              style={column.style ? column.style : null}
                              body={(rowData) => {
                                if (
                                  column.name.toLowerCase().includes("date")
                                ) {
                                  return (
                                    rowData[column.value] &&
                                    formatDate(rowData[column.value])
                                  );
                                } else if (
                                  column.template &&
                                  typeof column.template === "function"
                                ) {
                                  return column.template(rowData);
                                } else {
                                  return rowData[column.value];
                                }
                              }}
                            />
                          ))}
                        </DataTable>
                      </div>
                    </div>
                  ) : null}
                  {rule.action == "removeAllExcept" ? (
                    <div className="flex flex-col gap-4">
                      <div>
                        <DataTable
                          value={getAllBillableItems()}
                          className="rounded-lg border-2 border-gray-200"
                          emptyMessage={
                            <p className="text-center">
                              Currently, there are no billable items selected.
                            </p>
                          }
                        >
                          {[
                            {
                              name: "Billable Items To Keep",
                              template: (e) =>
                                removeAllExceptBillableItemsTemplate(
                                  e,
                                  ruleIndex,
                                  rule,
                                ),
                              header: "Billable Items To Keep",
                            },
                            { name: "HCPCS", value: "hcpcs" },
                            {
                              name: "Description",
                              template: descriptionWithTooltipTemplate,
                            },
                            {
                              name: "Mod 1",
                              template: (e) => modTemplate(e, "mod1"),
                              header: "Mod 1",
                            },
                            {
                              name: "Mod 2",
                              template: (e) => modTemplate(e, "mod2"),
                              header: "Mod 2",
                            },
                            {
                              name: "Mod 3",
                              template: (e) => modTemplate(e, "mod3"),
                              header: "Mod 3",
                            },
                            {
                              name: "Mod 4",
                              template: (e) => modTemplate(e, "mod4"),
                              header: "Mod 4",
                            },
                            {
                              name: "Price per unit",
                              template: priceTemplate,
                              header: "Price per unit",
                            },
                          ].map((column) => (
                            <Column
                              key={column.name}
                              header={column.name}
                              align="center"
                              alignHeader="center"
                              style={column.style ? column.style : null}
                              body={(rowData) => {
                                if (
                                  column.name.toLowerCase().includes("date")
                                ) {
                                  return (
                                    rowData[column.value] &&
                                    formatDate(rowData[column.value])
                                  );
                                } else if (
                                  column.template &&
                                  typeof column.template === "function"
                                ) {
                                  return column.template(rowData);
                                } else {
                                  return rowData[column.value];
                                }
                              }}
                            />
                          ))}
                        </DataTable>
                      </div>
                      <div className="flex flex-col gap-2">
                        <div className="font-semibold">
                          Billable Items that will be removed
                        </div>
                        <DataTable
                          value={
                            rule.billableItems.length > 0
                              ? differenceByKey(
                                  getAllBillableItems(),
                                  rule.billableItems,
                                  "id",
                                )
                              : []
                          }
                          className="rounded-lg border-2 border-gray-200"
                          emptyMessage={
                            <p className="text-center">
                              Currently, there are no billable items selected.
                            </p>
                          }
                        >
                          {[
                            { name: "HCPCS", value: "hcpcs" },
                            {
                              name: "Description",
                              template: descriptionWithTooltipTemplate,
                            },
                            {
                              name: "Mod 1",
                              template: (e) => modTemplate(e, "mod1"),
                              header: "Mod 1",
                            },
                            {
                              name: "Mod 2",
                              template: (e) => modTemplate(e, "mod2"),
                              header: "Mod 2",
                            },
                            {
                              name: "Mod 3",
                              template: (e) => modTemplate(e, "mod3"),
                              header: "Mod 3",
                            },
                            {
                              name: "Mod 4",
                              template: (e) => modTemplate(e, "mod4"),
                              header: "Mod 4",
                            },
                            {
                              name: "Price per unit",
                              template: priceTemplate,
                              header: "Price per unit",
                            },
                          ].map((column) => (
                            <Column
                              key={column.name}
                              header={column.name}
                              align="center"
                              alignHeader="center"
                              style={column.style ? column.style : null}
                              body={(rowData) => {
                                if (
                                  column.name.toLowerCase().includes("date")
                                ) {
                                  return (
                                    rowData[column.value] &&
                                    formatDate(rowData[column.value])
                                  );
                                } else if (
                                  column.template &&
                                  typeof column.template === "function"
                                ) {
                                  return column.template(rowData);
                                } else {
                                  return rowData[column.value];
                                }
                              }}
                            />
                          ))}
                        </DataTable>
                      </div>
                    </div>
                  ) : null}
                  {rule.action == "removeMatchingPattern" ? (
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-2">
                        <label
                          htmlFor="removeMatchingPattern"
                          className="font-semibold"
                        >
                          Pattern to Match
                        </label>
                        <InputText
                          name="removeMatchingPattern"
                          id="removeMatchingPattern"
                          className="w-full md:w-1/2"
                          placeholder="Enter a valid pattern*"
                          onChange={(e) => {
                            const allItems = getAllBillableItems();
                            const matchingHcpcs = allItems.filter((item) =>
                              matchPattern(item.hcpcs, e.target.value),
                            );

                            const newRules = rules.length
                              ? [...rules]
                              : [...allRules];

                            newRules[ruleIndex].billableItems = matchingHcpcs;

                            rules.length
                              ? setRules(newRules)
                              : setAllRules(newRules);
                          }}
                        />
                        <div className="rounded-lg border border-slate-100 p-4">
                          <p className="text-sm text-gray-500">
                            Valid patterns:
                            <div className="p-2">
                              <span className="font-medium">*:</span> any
                              characters
                              <br /> <span className="font-medium">?:</span> a
                              single character
                              <br /> <span className="font-medium">#:</span> a
                              single digit
                              <br /> <span className="font-medium">
                                [A-Z]:
                              </span>{" "}
                              any character in range
                              <br />{" "}
                              <span className="font-medium">[!A-Z]:</span> any
                              character not in range
                            </div>
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <div className="font-semibold">
                          Billable Items that will be removed
                        </div>
                        <DataTable
                          value={rule.billableItems}
                          className="rounded-lg border-2 border-gray-200"
                          emptyMessage={
                            <p className="text-center">
                              No Matching HCPCS Code(s)
                            </p>
                          }
                        >
                          {[
                            { name: "HCPCS", value: "hcpcs" },
                            {
                              name: "Description",
                              template: descriptionWithTooltipTemplate,
                            },
                            {
                              name: "Mod 1",
                              template: (e) => modTemplate(e, "mod1"),
                              header: "Mod 1",
                            },
                            {
                              name: "Mod 2",
                              template: (e) => modTemplate(e, "mod2"),
                              header: "Mod 2",
                            },
                            {
                              name: "Mod 3",
                              template: (e) => modTemplate(e, "mod3"),
                              header: "Mod 3",
                            },
                            {
                              name: "Mod 4",
                              template: (e) => modTemplate(e, "mod4"),
                              header: "Mod 4",
                            },
                            {
                              name: "Price per unit",
                              template: priceTemplate,
                              header: "Price per unit",
                            },
                          ].map((column) => (
                            <Column
                              key={column.name}
                              header={column.name}
                              align="center"
                              alignHeader="center"
                              style={column.style ? column.style : null}
                              body={(rowData) => {
                                if (
                                  column.name.toLowerCase().includes("date")
                                ) {
                                  return (
                                    rowData[column.value] &&
                                    formatDate(rowData[column.value])
                                  );
                                } else if (
                                  column.template &&
                                  typeof column.template === "function"
                                ) {
                                  return column.template(rowData);
                                } else {
                                  return rowData[column.value];
                                }
                              }}
                            />
                          ))}
                        </DataTable>
                      </div>
                    </div>
                  ) : null}
                  {rule.action == "addLocations" ||
                  rule.action == "removeLocations" ? (
                    <div className="flex flex-col gap-2">
                      <div>
                        <label className="font-semibold">
                          Filter Locations
                        </label>
                        <p className="text-xs text-gray-400">
                          * Please note that locations flagged as disabled will
                          not be visible to order providers.
                        </p>
                      </div>
                      <MultiSelect
                        value={mainLocation.filter((obj) =>
                          rule.locations.includes(obj.id),
                        )}
                        onChange={(e) => {
                          const newRules = [...rules];
                          newRules[ruleIndex].locations = e.value.map(
                            (loc) => loc.id,
                          );
                          setRules(newRules);
                        }}
                        selectedItemTemplate={(value) => {
                          return (
                            value && (
                              <span
                                className={`mr-1 rounded-full border border-gray-200 bg-gray-50 px-3 py-1 font-semibold capitalize ${value.isEnabled ? "text-gray-600" : "text-gray-400"}`}
                              >
                                {value.name}
                              </span>
                            )
                          );
                        }}
                        itemTemplate={(option) => (
                          <div>
                            <h4>
                              <span
                                className={`font-medium capitalize ${option.isEnabled ? "text-gray-600" : "text-gray-400"}`}
                              >
                                {option.name}{" "}
                              </span>
                              {!option.isEnabled && (
                                <span className="text-sm italic text-gray-400">
                                  (disabled)
                                </span>
                              )}
                            </h4>
                            <p
                              className={`font-normal capitalize ${option.isEnabled ? "text-gray-600" : "text-gray-400"}`}
                            >{`${option.address.lineOne}, ${
                              option.address.lineTwo
                                ? option.address.lineTwo + ","
                                : ""
                            } ${option.address.city}, ${option.address.state} ${
                              option.address.zipCode
                            }`}</p>
                          </div>
                        )}
                        options={mainLocation}
                        optionLabel="name"
                        filter
                        id="select-locations"
                        placeholder="Select Billable Items Locations"
                        className="md:w-20rem w-full"
                      />
                    </div>
                  ) : null}

                  {rule.action == "removeUnitsFromExisting" ||
                  rule.action == "AddUnitsToExisting" ? (
                    <>
                      <DataTable value={getAllBillableItems(rule)}>
                        {[
                          { name: "HCPCS", value: "hcpcs" },
                          {
                            name: "Description",
                            template: descriptionWithTooltipTemplate,
                          },
                          {
                            name: "Mod 1",
                            template: (e) => modTemplate(e, "mod1"),
                            header: "Mod 1",
                          },
                          {
                            name: "Mod 2",
                            template: (e) => modTemplate(e, "mod2"),
                            header: "Mod 2",
                          },
                          {
                            name: "Mod 3",
                            template: (e) => modTemplate(e, "mod3"),
                            header: "Mod 3",
                          },
                          {
                            name: "Mod 4",
                            template: (e) => modTemplate(e, "mod4"),
                            header: "Mod 4",
                          },
                          {
                            name: "Price per unit",
                            template: priceTemplate,
                            header: "Price per unit",
                          },
                          { name: "Units", value: "units" },
                          {
                            name:
                              rule.action == "removeUnitsFromExisting"
                                ? "Decrement by"
                                : "Increment by",
                            template: (e) =>
                              changeExistingUnit(e, ruleIndex, rule),
                            header:
                              rule.action == "removeUnitsFromExisting"
                                ? "Decrement by"
                                : "Increment by",
                          },
                        ].map((column) => (
                          <Column
                            key={column.name}
                            header={column.name}
                            align="center"
                            alignHeader="center"
                            style={column.style ? column.style : null}
                            body={(rowData) => {
                              if (column.name.toLowerCase().includes("date")) {
                                return (
                                  rowData[column.value] &&
                                  formatDate(rowData[column.value])
                                );
                              } else if (
                                column.template &&
                                typeof column.template === "function"
                              ) {
                                return column.template(rowData);
                              } else {
                                return rowData[column.value];
                              }
                            }}
                          />
                        ))}
                      </DataTable>
                    </>
                  ) : null}
                  {rule.action == "swapCPT" ? (
                    <div className="flex flex-col gap-3">
                      <p className="flex items-center gap-2 font-inter text-lg font-bold text-gray-500">
                        <i className="pi pi-minus text-red-500"></i>
                        Billable Item To Remove
                      </p>
                      <div className="flex flex-col gap-2 rounded-lg border border-slate-100 p-5">
                        <SwappedBillableTable value={rule.billableItems[0]} />
                        <Button
                          className="self-end"
                          label="Choose a billable item to remove"
                          onClick={() => setBillableToSwapDialogVisible(true)}
                        />
                      </div>
                      <p className="flex items-center gap-2 font-inter text-lg font-bold text-gray-500">
                        <i className="pi pi-plus text-green-500"></i>
                        Billable Item To Add
                      </p>
                      <div className="flex flex-col gap-2 rounded-lg border border-slate-100 p-5">
                        <SwappedBillableTable value={rule.billableItems[1]} />
                        <Button
                          className="self-end"
                          label="Choose a billable item to add"
                          onClick={() => setSwappedBillableDialogVisible(true)}
                        />
                      </div>
                    </div>
                  ) : null}
                  {rule.action == "addDocs" || rule.action == "removeDocs" ? (
                    <div>
                      <SelectDocuments
                        action={rule.action}
                        getAllServiceDocs={getAllServiceDocs}
                        serviceDocumentsIds={serviceDocumentsIds}
                        selectedDocs={rule.documents}
                        handleSaveSelection={(unsavedSelectedDocs) => {
                          const newRules = rules.length
                            ? [...rules]
                            : [...allRules];

                          newRules[ruleIndex].documents =
                            unsavedSelectedDocs.map((doc) => doc.id);

                          rules.length
                            ? setRules(newRules)
                            : setAllRules(newRules);
                        }}
                        handleAddNew={(id) => {
                          const newRules = rules.length
                            ? [...rules]
                            : [...allRules];

                          newRules[ruleIndex].documents.push(id);

                          rules.length
                            ? setRules(newRules)
                            : setAllRules(newRules);
                        }}
                        handleRemove={(rowData) => {
                          const newRules = rules.length
                            ? [...rules]
                            : [...allRules];

                          newRules[ruleIndex].documents = newRules[
                            ruleIndex
                          ].documents.filter((doc) => doc !== rowData.id);

                          rules.length
                            ? setRules(newRules)
                            : setAllRules(newRules);
                        }}
                      />
                    </div>
                  ) : null}
                </div>

                {ruleIndex > 0 && <Divider />}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const { isMobile } = useHandleResize();

  return (
    <div className="rounded bg-white px-3 py-5">
      <Button
        label="Add Rule"
        icon="pi pi-plus"
        outlined
        className="mb-4 mr-2 w-full sm:w-auto"
        severity="info"
        onClick={handleAddRule}
      />
      <Button
        label="Add group of rules "
        icon="pi pi-plus"
        severity="info"
        className="mb-4 w-full sm:w-auto"
        onClick={handleAddCombination}
      />
      <Dialog
        draggable={false}
        blockScroll
        visible={visible}
        className="w-11/12 lg:w-[80vw]"
        onHide={() => {
          confirmDialog({
            message:
              "Are you sure you want to discard the changes you have made?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            acceptLabel: "Discard",
            rejectLabel: "Keep Changes",
            pt: {
              root: {
                style: {
                  width: `${isMobile ? "90%" : ""}`,
                },
              },
            },
            accept,
            reject,
          });
        }}
        header="Manage Rules"
      >
        {mapFunc(rules)}
        <div className="flex w-full flex-col justify-end gap-2 py-4 sm:flex-row">
          <div className="flex w-full gap-2 lg:w-1/3">
            <Button
              label="Cancel"
              outlined
              onClick={() => {
                confirmDialog({
                  message:
                    "Are you sure you want to discard the changes you have made?",
                  header: "Confirmation",
                  icon: "pi pi-exclamation-triangle",
                  acceptLabel: "Discard",
                  rejectLabel: "Keep Changes",
                  pt: {
                    root: {
                      style: {
                        width: `${isMobile ? "90%" : ""}`,
                      },
                    },
                  },
                  accept,
                  reject,
                });
              }}
              className="w-1/2"
            />
            <Button
              label="Save Rule"
              icon="pi pi-save"
              severity="success"
              className="w-1/2 p-2 md:px-5 md:py-3"
              onClick={() => {
                const isValid = formValidation([...rules]);
                if (!isValid) {
                  toast.current.show({
                    severity: "error",
                    summary: "Error",
                    detail: "Please add at least one rule",
                    life: 4000,
                  });
                  return;
                }
                if (isEditingRule) {
                  const prevAction = allRules[currentEditingRules].action;
                  const currentAction = rules[0].action;
                  if (prevAction === "addCPT") {
                    const isBillableItemUsed = (rules, id) => {
                      return rules.some(
                        (rule) =>
                          rule?.defaultUnitsChange?.some((b) => b.id === id) ||
                          (rule?.action === "removeCPT" &&
                            rule?.billableItems?.some((b) => b.id === id)),
                      );
                    };
                    const usedBillableItems = allRules[
                      currentEditingRules
                    ].billableItems.filter((b) =>
                      isBillableItemUsed(allRules, b.id),
                    );

                    if (usedBillableItems.length) {
                      if (currentAction !== "addCPT") {
                        showBillableItemInUseToast();
                        return;
                      } else {
                        const usedBillableItemsRemoved =
                          !usedBillableItems.every((usedBillable) =>
                            rules[0].billableItems
                              .map((billable) => billable.id)
                              .includes(usedBillable.id),
                          );
                        if (usedBillableItemsRemoved) {
                          showBillableItemInUseToast();
                          return;
                        }
                      }
                    }
                  }
                  let cloneAllRules = [...allRules];
                  cloneAllRules.splice(currentEditingRules, 1, ...rules);
                  setVisible(false);
                  setAllRules([...cloneAllRules]);
                  setRules([]);
                  setIsEditingRule(false);
                } else {
                  setVisible(false);
                  setAllRules([...allRules, ...rules]);
                  setRules([]);
                }
                toast.current.show({
                  severity: "success",
                  summary: "Success",
                  detail: "Rules Saved Successfully",
                  life: 4000,
                });
              }}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default RuleForm;
