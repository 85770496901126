import { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from "primereact/inputtextarea";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useOverlayScrollListener } from "primereact/hooks";

import useAxios from "../../../../hooks/useAxios";
import useTokenData from "../../../../hooks/useTokenData";
import { UploadFiles } from "../../../../components/dialogs";
import useHandleResize from "../../../../hooks/useHandleResize";
import { ReusableDataList } from "../../../../components/Table";
import useDelayedInputChange from "../../../../hooks/useDelayedInputChange";
import { PatientInfoTemplate } from "../../../../components/templates/columnBodyTemplates";
import {
  downloadFileFromResponse,
  formatDate,
  formatDateTime,
  RENDERING_DROPDOWN_OPTIONS,
} from "../../../../utils/helpers";

function PendingOrders() {
  const [menuvisible, setMenuVisible] = useState(false);
  const buttonRef = useRef(null);
  const { entityId } = useTokenData();
  const menuRight = useRef({});
  const navigate = useNavigate();
  const { http } = useAxios();
  const [visible, setVisible] = useState(false);
  const [orderData, setOrderData] = useState();
  const [date, setDate] = useState(null);
  const toast = useRef(null);
  const [refetch, setRefetch] = useState();
  const [uploadDialog, setUploadDialog] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [storedFiles, setStoredFiles] = useState();
  const [selectedValue, setSelectedValue] = useState("Service.Name@=*");
  const [searchValue, handleInputChange] = useDelayedInputChange(
    refetch,
    setRefetch,
    selectedValue,
  );
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [confirmData, setConfirmData] = useState();
  const [cancelLoading, setCancelLoading] = useState(false);
  const { isMobile } = useHandleResize();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [preferredLocation, setPreferredLocation] = useState(null);
  const [Locations, setLocations] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  /************ Location and date schedule************************/
  const handleLocationChange = (e) => {
    setSelectedLocation(e.value);
    if (e.value) {
      setError("");
    }
  };

  const handleScroll = () => {
    setMenuVisible(false);
  };

  const [bindOverlayScrollListener, unbindOverlayScrollListener] =
    useOverlayScrollListener({
      target: buttonRef.current,
      listener: handleScroll,
      options: { passive: true },
      when: menuvisible,
    });

  useEffect(() => {
    bindOverlayScrollListener();

    return () => {
      unbindOverlayScrollListener();
    };
  }, [bindOverlayScrollListener, unbindOverlayScrollListener]);

  const getLocations = async (rowData) => {
    try {
      const response = await http.get(
        `/Orders/GetOrderRenderedLocations/${rowData.id}`,
      );
      const locationData = response.data;
      setLocations(locationData);
      const allRenderProviderFalse = locationData.every(
        (loc) => !loc.isSelectedByRenderProvider,
      );

      const preferredLocation = locationData.find(
        (loc) => loc.isPreferredByPatient,
      );
      setPreferredLocation(preferredLocation);
      const defaultLocation = locationData.find(
        (loc) => loc.isSelectedByRenderProvider,
      );

      let selectedLocation;

      if (allRenderProviderFalse && preferredLocation) {
        selectedLocation = preferredLocation.locationId;
      } else if (defaultLocation) {
        selectedLocation = defaultLocation.locationId;
      }

      if (selectedLocation) {
        setSelectedLocation(selectedLocation);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSchedule = (rowData) => {
    const scheduledDate = rowData.scheduledDate;
    const currentDate = scheduledDate ? new Date(scheduledDate) : new Date();
    setDate(currentDate);
    setVisible(true);
    setOrderData(rowData);
    getLocations(rowData);
  };

  const dateRequest = async (date, selectedLocation) => {
    if (!selectedLocation) {
      setError("Please select a location.");
    } else {
      const data = new FormData();
      data.append("Status", 9);
      data.append("ScheduledDate", date.toISOString());
      const renderProviderTimeZone =
        Intl.DateTimeFormat().resolvedOptions().timeZone;
      data.append("RenderProviderTimeZone", renderProviderTimeZone);
      data.append("SelectedLocationsByRender", selectedLocation);
      try {
        setIsLoading(true);
        await http.put(`/Orders/UpdateOrder/${orderData.id}`, data);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
      setRefetch((e) => !refetch);
      setVisible(false);
    }
  };

  const scheduledDateTemplate = (rowData) => {
    return (
      <>
        <div className="flex items-center justify-between gap-2">
          <div className="flex flex-col">
            <p>{rowData.scheduledDate && formatDate(rowData.scheduledDate)}</p>
            <p className="mt-1 font-inter text-sm">
              {rowData.scheduledDate && formatDateTime(rowData.scheduledDate)}
            </p>
          </div>
          {rowData.scheduledDate && (
            <div>
              <i
                onClick={() => {
                  if (rowData.hasResultFiles) {
                    toast.current.show({
                      severity: "warn",
                      summary: "Warning",
                      detail:
                        "You can't edit scheduled date after uploading results",
                      life: 4000,
                    });
                  } else {
                    handleSchedule(rowData);
                  }
                }}
                className={`${
                  rowData.hasResultFiles ? "text-gray-500" : "text-blue-600"
                } pi pi-pencil custom-target-icon mr-2 cursor-pointer`}
                data-pr-tooltip="Edit"
                data-pr-position="top"
              ></i>
            </div>
          )}
        </div>
      </>
    );
  };
  /***************************************** */

  const getUploadedFiles = async (rowData) => {
    const res = await http.get(`/Orders/GetOrderResultFiles/${rowData?.id}`);
    setStoredFiles(res.data);
  };

  const downloadPatientInfo = async (rowData) => {
    try {
      const response = await http.get(
        `/Orders/DownloadOrderSummary/${rowData.id}`,
        {
          responseType: "blob",
        },
      );
      downloadFileFromResponse(response);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Something went wrong. Please try again later",
        life: 3000,
      });
    }
  };
  const downloadReceipt = async (rowData) => {
    try {
      const response = await http.get(
        `/Orders/DownloadRendringRecieptSummary/${rowData.id}`,
        {
          responseType: "blob",
        },
      );
      downloadFileFromResponse(response);
      toast.current.show({
        severity: "success",
        summary: "success",
        detail: response.data.message || "File download successfully",
        life: 3000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Something went wrong. Please try again later",
        life: 3000,
      });
    }
  };
  const downloadPatientAttachments = async (rowData) => {
    try {
      const response = await http.get(
        `/Orders/DownloadPatientAttachmentsByOrderId/${rowData.id}`,
        {
          responseType: "blob",
        },
      );
      downloadFileFromResponse(response);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Something went wrong. Please try again later",
        life: 3000,
      });
    }
  };
  /*********************cancel order ********************************** */

  const handleCancelOrder = async () => {
    try {
      setCancelLoading(true);
      const data = new FormData();
      data.append("Status", 11);
      data.append("CancelationResone", cancelReason);
      const response = await http.put(
        `/Orders/UpdateOrder/${confirmData.id}`,
        data,
      );
      setRefetch((e) => !refetch);
      setCancelDialogVisible(false);
      setCancelReason("");
      toast.current.show({
        severity: "success",
        summary: "success",
        detail: response.data.message || "order cancel successfully",
        life: 3000,
      });
    } catch (error) {
      console.error("Error updating order:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Something went wrong. Please try again later",
        life: 3000,
      });
    } finally {
      setCancelLoading(false);
    }
  };
  const confirm = (rowData) => {
    setCancelDialogVisible(true);
    setConfirmData(rowData);
  };
  const dialogCancelOrder = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => {
          setCancelDialogVisible(false);
          setCancelReason("");
        }}
        className="p-button-text"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={handleCancelOrder}
        loading={cancelLoading}
        disabled={!cancelReason.trim()}
        autoFocus
      />
    </div>
  );
  const orderInfo = async (rowData) => {
    navigate(`/rendering/Orders/${rowData.id}`, {
      state: {
        orderNumber: rowData.orderTrackingNumber,
        serviceName: rowData.serviceName,
      },
    });
  };

  const statusTemplate = (rowData) => {
    // Customize this function based on your actionTemplate logic
    return (
      <>
        {rowData.status === 8 ? (
          <div className="inline rounded-md bg-sky-100 p-2 text-center text-sm text-sky-700 sm:block sm:p-3 sm:text-base">
            Ordered
          </div>
        ) : (
          <div className="inline rounded-md bg-light-rose p-2 text-center text-sm text-dark-rose sm:block sm:p-3 sm:text-base">
            Scheduled
          </div>
        )}
      </>
    );
  };
  const chargeTemplate = (rowData) => (
    <>
      <h4 className="font-inter text-sm font-semibold"> ${rowData.charge} </h4>
    </>
  );
  const serviceTypeTemplate = (rowData) => {
    return (
      <>
        {rowData.serviceType === 1 ? (
          <p className="inline rounded-md bg-orange-100 p-2 text-center text-sm text-orange-600 sm:block sm:p-3 sm:text-base">
            Scheduled Appointment Service
          </p>
        ) : (
          <p
            className="inline rounded-md p-2 text-center text-sm sm:block sm:p-3 sm:text-base"
            style={{ backgroundColor: "#A0D9D9", color: "#2F4F4F" }}
          >
            Walk-In Service
          </p>
        )}
      </>
    );
  };
  const getMenuItems = (rowData) => {
    const items = [];

    if (rowData.status !== 8) {
      items.push({
        label: "Upload Result",
        icon: "pi pi-upload",
        command: () => {
          setUploadDialog(true);
          getUploadedFiles(rowData);
          setOrderData(rowData);
        },
      });
    }

    if (rowData.status === 8) {
      if (rowData.serviceType === 1) {
        items.push({
          label: "Schedule",
          icon: "pi pi-calendar",
          command: () => handleSchedule(rowData),
        });
      } else {
        items.push({
          label: "Upload Result",
          icon: "pi pi-upload",
          command: () => {
            setUploadDialog(true);
            getUploadedFiles(rowData);
            setOrderData(rowData);
          },
        });
      }
    }

    items.push({
      label: "Cancel",
      icon: "pi pi-times",
      command: () => {
        confirm(rowData);
        setCancelDialogVisible(true);
        setCancelReason("");
      },
    });

    items.push({
      label: "Download Order Summary",
      icon: "pi pi-download",
      command: () => downloadPatientInfo(rowData),
    });
    items.push({
      label: "Download receipt Summary",
      icon: "pi pi-download",
      command: () => downloadReceipt(rowData),
    });
    if (rowData.hasAttachmentFiles) {
      items.push({
        label: "Download Patient Attachments",
        icon: "pi pi-download",
        command: () => downloadPatientAttachments(rowData),
      });
    }

    items.push({
      label: "Order Info",
      icon: "pi pi-info-circle",
      command: () => orderInfo(rowData),
    });
    return items;
  };

  const actionTemplate = (rowData) => {
    return (
      <>
        <Button
          ref={buttonRef}
          icon="pi pi-ellipsis-v"
          text
          onClick={(event) => {
            setMenuVisible(true);
            menuRight.current[rowData.id].toggle(event);
          }}
          aria-controls={`popup_menu_right_${rowData.id}`}
          aria-haspopup
        />
        <Menu
          model={getMenuItems(rowData)}
          popup
          ref={(el) => (menuRight.current[rowData.id] = el)}
          id={`popup_menu_right_${rowData.id}`}
          className={menuvisible ? "" : "hidden"}
        />
      </>
    );
  };

  const dropdownValues = {
    options: RENDERING_DROPDOWN_OPTIONS,
    selectedValue: selectedValue,
    setSelectedValue: setSelectedValue,
  };

  const cardTemplate = (row) => {
    return (
      <>
        <div className="flex justify-between gap-2">
          <p>#{row.orderTrackingNumber}</p>
          <p className="text-slate-500">{formatDate(row.orderedDate)}</p>
        </div>
        <div className="flex justify-between gap-2">
          <p className="font-semibold">{row.serviceName}</p>
          <p className="font-semibold">
            <i className="pi pi-dollar text-green-700" />
            <span>{row.charge}</span>
          </p>
        </div>
        <p className="font-semibold"> {serviceTypeTemplate(row)}</p>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            &nbsp;Ordered By:&nbsp;
          </span>
          {row.orderedBy}
        </p>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            &nbsp;Patient Info:&nbsp;
          </span>
          <PatientInfoTemplate rowData={row} />
        </p>
        <p className="font-semibold">
          <i className="pi pi-calendar-times" />
          <span className="font-semibold text-slate-500">
            &nbsp;Scheduled Date:&nbsp;
          </span>
          {row.scheduledDate && (
            <>
              <span>{formatDate(row.scheduledDate)}&nbsp;</span>
              <span>{formatDateTime(row.scheduledDate)}</span>
            </>
          )}
        </p>
        <div className="flex justify-between gap-2">
          <div className="flex items-center gap-2 font-semibold">
            <span className="font-semibold text-slate-500">Status:&nbsp;</span>

            {statusTemplate(row)}
          </div>
          {actionTemplate(row)}
        </div>
      </>
    );
  };

  const filterTemplate = () => {
    return (
      <div>
        <h3 className="text-md mb-3 font-bold">Search By</h3>
        <div className="flex flex-col gap-2">
          {dropdownValues.options.map((option) => {
            return (
              <div key={option.name} className="align-items-center flex">
                <RadioButton
                  inputId={option.name}
                  name="name"
                  value={option}
                  onChange={(e) => dropdownValues?.setSelectedValue(e.value)}
                  checked={dropdownValues.selectedValue === option.name}
                />
                <label htmlFor={option.name} className="ml-2 capitalize">
                  {option.name}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <Dialog
        blockScroll
        draggable={false}
        header="Schedule Date and Location"
        visible={visible}
        onHide={() => {
          setUploadedFiles([]);
          setStoredFiles([]);
          setLocations([]);
          setSelectedLocation(null);
          setVisible(false);
        }}
        className="w-[35rem]"
      >
        <Calendar
          value={date}
          dateFormat="mm/dd/yy"
          onChange={(e) => {
            setDate(e.value);
          }}
          minDate={new Date()}
          inline
          showTime
          hourFormat="12"
          pt={{
            day: {
              style: { padding: "0", margin: "0" },
            },
            header: {
              style: { padding: "0", margin: "0" },
            },
            table: {
              style: { padding: "0", margin: "0" },
            },
            timePicker: {
              style: { padding: "0", margin: "0" },
            },
            hourPicker: {
              style: { padding: "0", margin: "0" },
            },
            panel: {
              style: { padding: "0", margin: "0" },
            },
            minute: {
              style: { padding: "0", margin: "0", fontSize: "16px" },
            },
            hour: {
              style: { padding: "0", margin: "0", fontSize: "16px" },
            },
            ampm: {
              style: { padding: "0", margin: "0", fontSize: "16px" },
            },
          }}
          className="mb-2 w-full p-0"
        />

        <span className="col-span-1 flex flex-col gap-2">
          <label className="mt-3 block text-lg font-medium capitalize text-dark-purple">
            select location <span className="text-red-500">*</span>
          </label>
          <Dropdown
            id="selected-location"
            value={selectedLocation}
            placeholder="Locations"
            options={Locations?.map((option) => ({
              value: option.locationId,
              label: option.name,
              address: option.address,
              isSelectedByRenderProvider: option.isSelectedByRenderProvider,
              isPreferredByPatient: option.isPreferredByPatient,
            }))}
            onChange={handleLocationChange}
            itemTemplate={(option) => (
              <div className="grid w-full grid-cols-8 items-center rounded">
                <div className="col-span-1 flex items-center justify-center">
                  {option.value === selectedLocation ? (
                    <i className="pi pi-check pr-2 text-xl text-green-500"></i>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-span-7">
                  <h4 className="flex items-center gap-2 font-medium capitalize text-gray-500">
                    <span>{option.label}</span>
                    {option.isPreferredByPatient && (
                      <span className="text-xs font-normal italic">
                        (Preferred by Patient)
                      </span>
                    )}
                  </h4>
                  <p className="font-normal capitalize text-gray-500">{`${
                    option.address.lineOne
                  }, ${
                    option.address.lineTwo ? option.address.lineTwo + "," : ""
                  } ${option.address.city}, ${option.address.state} ${
                    option.address.zipCode
                  }`}</p>
                </div>
              </div>
            )}
            pt={{
              panel: {
                style: {
                  border: "solid 2px #e5e7eb",
                  borderTopStyle: "none",
                },
              },
            }}
            className="md:w-14rem w-full"
          />
          {error && <p className="text-red-500">{error}</p>}
          {preferredLocation && (
            <p className="border-l-2 px-2 text-sm text-gray-500">
              <strong>Note:</strong> While the patient's preferred location is
              pre-selected, you have the flexibility to choose a different
              location that suits your scheduling and service requirements.
            </p>
          )}
        </span>

        <div className="text-end">
          <Button
            label="Schedule"
            className="mt-3"
            loading={isLoading}
            onClick={() => dateRequest(date, selectedLocation)}
          />
        </div>
      </Dialog>

      <Dialog
        blockScroll
        draggable={false}
        header="Confirmation to cancel order"
        visible={cancelDialogVisible}
        onHide={() => {
          setCancelDialogVisible(false);
          setCancelReason("");
        }}
        footer={dialogCancelOrder}
        className="w-[35rem]"
      >
        <p className="mb-3 font-semibold">
          Are you sure you want to proceed with cancelling this order?{" "}
        </p>
        <span className="block capitalize"> Please provide the reason. </span>
        <p className="mt-2">
          <InputTextarea
            value={cancelReason}
            placeholder="cancelation reason"
            onChange={(e) => {
              setCancelReason(e.target.value);
            }}
            className="w-full"
          />
        </p>
      </Dialog>

      {uploadedFiles ? (
        <UploadFiles
          refetch={refetch}
          setRefetch={setRefetch}
          orderData={orderData}
          uploadDialog={uploadDialog}
          setUploadDialog={setUploadDialog}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          storedFiles={storedFiles}
          setStoredFiles={setStoredFiles}
        />
      ) : null}

      <ReusableDataList
        title={"List of orders"}
        sendSearchData={handleInputChange}
        searchValue={searchValue}
        dropdownValues={dropdownValues}
        dataLink={`/Orders/GetOrders/${entityId}?Filters=${
          searchValue ? selectedValue : ""
        }${searchValue}%2Cstatus==8|9`}
        columns={[
          {
            name: "Order Number",
            value: "orderTrackingNumber",
            style: { width: "8%" },
          },
          {
            name: "Service Name",
            value: "serviceName",
            style: { width: "15%" },
          },
          {
            name: "Service Type",
            value: "serviceType",
            template: serviceTypeTemplate,
            style: { width: "13%" },
          },
          {
            name: "Charge",
            value: "charge",
            template: chargeTemplate,
            style: { width: "8%" },
          },
          { name: "Ordered By", value: "orderedBy", style: { width: "13%" } },
          { name: "Date", value: "orderedDate", style: { width: "13%" } },
          {
            name: "Patient Info",
            value: "patientName",
            template: (rowData) => <PatientInfoTemplate rowData={rowData} />,
            style: { width: "13%" },
          },
        ]}
        actionTemplates={
          isMobile
            ? [{ template: cardTemplate }]
            : [
                {
                  template: scheduledDateTemplate,
                  header: "Scheduled Date",
                  style: { width: "13%" },
                },
                {
                  template: statusTemplate,
                  header: "Status",
                  style: { width: "13%" },
                },
                { template: actionTemplate, header: "Actions" },
              ]
        }
        filterTemplate={filterTemplate}
        refetch={refetch}
      />
    </>
  );
}

export default PendingOrders;
