import { useEffect } from "react";

import { Controller, useForm } from "react-hook-form";

import { Tooltip } from "primereact/tooltip";
import { Message } from "primereact/message";
import { Dropdown } from "primereact/dropdown";

import { SelectableCard } from "../../../../components/cards";
import ExistingOrderOnFileForm from "./ExistingOrderOnFileForm";

const ORDER_TYPE_OPTIONS = {
  CREATE_NEW: {
    label: "Create New Order",
    value: 1,
    description:
      "Select this option if you want to create a new order, get it authorized and authenticated by an ordering provider participating in your practice via the MedX platform.",
  },
  USE_EXISTING: {
    label: "Use Existing Order On-File",
    value: 2,
    description:
      "Select this option if you want to use the MedX platform to order the service, generate an accurate quote, and collect the funds electronically from the patient - but you do not need to create a new order or get it authorized or authenticated, because you already have a valid order on record in your electronic medical record system.",
  },
};

function InitiateOrder({
  isInternalOrder,
  submitRef,
  providers,
  initiateOrderData,
  setInitiateOrderData,
  hasUnCertifiedProviders,
  setActiveIndex,
  progressIndex,
  defaultValues,
}) {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues,
  });

  useEffect(() => {
    if (progressIndex === 0) {
      if (providers?.length === 1) {
        setValue("orderingProvider", providers[0]);
        if (!isInternalOrder) {
          handleSubmit(onSubmit)();
        }
      }
    } else {
      reset(initiateOrderData);
    }
  }, [providers, initiateOrderData]);

  const handleSelectOrderType = (value) => {
    reset({
      ...defaultValues,
      orderType: value,
      orderingProvider: "",
    });
  };

  const onSubmit = (formData) => {
    setInitiateOrderData(formData);
    setActiveIndex((old) => old + 1);
  };

  return (
    <div className="flex flex-col gap-6">
      <div>
        <h4 className="text-title font-extrabold capitalize text-dark-purple">
          Initiate Order
        </h4>
        <p className="min-w-sm w-full md:w-11/12">
          {isInternalOrder
            ? "To proceed with your order, please select the order type and the ordering provider approving the order."
            : "To proceed with your order, please select the provider associated with this order."}
        </p>
      </div>
      <form
        ref={submitRef}
        className="flex w-full flex-col gap-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* ORDER TYPE */}
        {isInternalOrder && (
          <div className="flex flex-col gap-1">
            <label htmlFor="orderType" className="font-semibold text-gray-800">
              Order Type
            </label>
            <div className="grid grid-cols-2 gap-5">
              <Controller
                name="orderType"
                control={control}
                render={({ field }) => (
                  <>
                    <SelectableCard
                      value={ORDER_TYPE_OPTIONS.CREATE_NEW.value}
                      field={field}
                      label={ORDER_TYPE_OPTIONS.CREATE_NEW.label}
                      onSelect={handleSelectOrderType}
                      selectedValue={watch("orderType")}
                      disabled={isInternalOrder && providers?.length === 0}
                      description={ORDER_TYPE_OPTIONS.CREATE_NEW.description}
                    />
                    <SelectableCard
                      value={ORDER_TYPE_OPTIONS.USE_EXISTING.value}
                      field={field}
                      label={ORDER_TYPE_OPTIONS.USE_EXISTING.label}
                      onSelect={handleSelectOrderType}
                      selectedValue={watch("orderType")}
                      description={ORDER_TYPE_OPTIONS.USE_EXISTING.description}
                    />
                  </>
                )}
              />
            </div>
          </div>
        )}
        {/* Sub Panel */}
        <div className="rounded-lg border p-4">
          {/* SELECT PROVIDER */}
          {(!isInternalOrder ||
            watch("orderType") === ORDER_TYPE_OPTIONS.CREATE_NEW.value) && (
            <div className="flex flex-col gap-1">
              <h5 className="font-semibold text-gray-700">Ordering Provider</h5>
              <p className="min-w-sm w-full text-sm text-gray-700">
                The new order will be transmitted to the following provider for
                review and approval via the MedX system. Once the provider
                approves the order electronically, payment will be captured from
                the patient and a valid and authentic order will exist for
                acceptance review by the rendering provider.
              </p>
              <Controller
                name="orderingProvider"
                control={control}
                rules={{ required: "Please select a provider" }}
                render={({ field }) => (
                  <div className="flex">
                    <Dropdown
                      {...field}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={providers}
                      optionLabel="fullName"
                      placeholder="Select your provider"
                      filter
                      filterBy="fullName"
                      showClear={providers?.length > 1}
                      className="md:w-14rem w-full"
                    />
                    {hasUnCertifiedProviders && (
                      <div className="flex w-1/12 cursor-pointer items-center justify-center">
                        <Tooltip target=".info-icon" />
                        <span
                          className="info-icon max-w-lg"
                          data-pr-position="top"
                          data-pr-tooltip={`Do you not see an ordering provider that you previously were able to select?
                            It is possible that the ordering provider's account requires re-certification by the practice for continued access.
                             Please contact the system administrator at the practice to get all valid ordering provider accounts re-certified and enabled`}
                        >
                          <i className="pi pi-question-circle text-lg" />
                        </span>
                      </div>
                    )}
                  </div>
                )}
              />
              {errors.orderingProvider && (
                <div className="text-red-500">
                  {errors.orderingProvider.message}
                </div>
              )}
              {providers?.length === 0 &&
                (hasUnCertifiedProviders ? (
                  <>
                    <Message
                      severity="error"
                      className="my-3"
                      text={
                        <span>
                          <span className="font-semibold">
                            Unfortunately all ordering providers are disabled
                            within your practice.
                          </span>{" "}
                          Please contact the system administrator at the
                          practice to get all valid ordering provider accounts
                          re-certified and enabled
                        </span>
                      }
                    />
                  </>
                ) : (
                  <div className="mt-5 flex rounded-lg bg-red-50 p-4 font-Poppins text-red-500 shadow-md">
                    <i className="pi pi-info-circle mt-1"></i>
                    <p className="pl-3">
                      You currently don't have a provider in your practice.
                      Please initiate a provider request first.
                    </p>
                  </div>
                ))}
            </div>
          )}
          {/* EXISTING ORDER ON FILE DETAILS*/}
          {watch("orderType") === ORDER_TYPE_OPTIONS.USE_EXISTING.value && (
            <div>
              <ExistingOrderOnFileForm
                control={control}
                setValue={setValue}
                errors={errors}
              />
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default InitiateOrder;
