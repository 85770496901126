import { useRef, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { BreadCrumb } from "primereact/breadcrumb";
import { ProgressSpinner } from "primereact/progressspinner";

import useAxios from "../../hooks/useAxios";
import { validatePassword } from "../../utils/helpers";
import useImpersonating from "../../hooks/useImpersonating";

function ChangePassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const toast = useRef(null);
  const { http, logOut } = useAxios();
  const { isSystemAdminImpersonating } = useImpersonating();

  const defaultValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const {
    handleSubmit,
    trigger,
    control,
    formState: { errors },
  } = useForm({ mode: "onChange", defaultValues: defaultValues });
  const location = useLocation();

  const navigate = useNavigate();
  const isAdmin = location.pathname.includes("admin");
  // handle password eye
  const [passwordEye, setPasswordEye] = useState(false);
  const [oldPasswordEye, setOldPasswordEye] = useState(false);
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(false);
  const handlePasswordClick = () => {
    setPasswordEye(!passwordEye);
  };
  const handleOldPasswordClick = () => {
    setOldPasswordEye(!oldPasswordEye);
  };
  const handleConfirmPasswordClick = () => {
    setConfirmPasswordEye(!confirmPasswordEye);
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);
    setIsDisabled(true);
    try {
      const response = await http.put(`/Authentication/ChangePassword`, {
        currentPassword: formData.oldPassword,
        newPassword: formData.newPassword,
        confirmPassword: formData.confirmPassword,
      });
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message,
        life: 2000,
      });
      if (!isSystemAdminImpersonating) {
        setTimeout(() => {
          logOut();
        }, 2000);
      } else {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail:
          error.response.data.message ||
          "Something went wrong. Please try again later",
        life: 2000,
      });

      setIsDisabled(false);
    }
    setIsLoading(false);
  };
  const handleCancel = () => {
    navigate(-1);
  };

  const breadCrumbsItems = isAdmin
    ? [
        {
          label: "Change Password",
          template: () => (
            <Link to={`/${location.pathname.split("/")[1]}/passwordSetting`}>
              Password Settings
            </Link>
          ),
        },
      ]
    : [
        {
          label: "Settings",
          template: () => (
            <Link to={`/${location.pathname.split("/")[1]}/Settings/`}>
              Settings
            </Link>
          ),
        },
        {
          label: "Change Password",
          template: () => <Link to="#">Change Password</Link>,
        },
      ];

  return (
    <>
      <Toast ref={toast} />
      <BreadCrumb
        model={breadCrumbsItems}
        className="border-0 bg-transparent pb-7 font-semibold"
        pt={{
          separator: { className: "first:hidden" },
          menuitem: { className: "last:text-purple-700" },
        }}
      />
      <div className="flex w-full items-center justify-center py-4 sm:px-8">
        <div className="w-full rounded-3xl bg-light-text p-8 shadow shadow-slate-300">
          <div>
            <h4 className="mb-6 text-center text-3xl font-bold sm:text-left">
              Update your profile password
            </h4>
          </div>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="pt-4">
                <label className="mb-2 block capitalize">
                  current password <span className="text-red-600">*</span>
                </label>
                <Controller
                  name="oldPassword"
                  control={control}
                  rules={{
                    required: "Current Password is required",
                    validate: validatePassword,
                  }}
                  render={({ field }) => (
                    <>
                      <span className="p-input-icon-left p-input-icon-right w-6/12">
                        <i className="pi pi-lock" />
                        <InputText
                          autoComplete="on"
                          placeholder="Password (8 or more characters)"
                          className="w-full pb-2 pt-2"
                          name="oldPassword"
                          disabled={isSystemAdminImpersonating}
                          type={!oldPasswordEye ? "password" : "text"}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                        />
                        {!oldPasswordEye ? (
                          <i
                            className="pi pi-eye-slash cursor-pointer"
                            onClick={handleOldPasswordClick}
                          />
                        ) : (
                          <i
                            className="pi pi-eye cursor-pointer"
                            onClick={handleOldPasswordClick}
                          />
                        )}
                      </span>
                    </>
                  )}
                />
                {errors.oldPassword && (
                  <p className="mt-1 text-red-500">
                    {errors.oldPassword.message}
                  </p>
                )}
              </div>

              <div className="mt-4 pt-4">
                <label className="mb-2 block capitalize">
                  new password <span className="text-red-600">*</span>
                </label>
                <Controller
                  name="newPassword"
                  control={control}
                  rules={{
                    required: "New Password is required",
                    validate: validatePassword,
                  }}
                  render={({ field }) => (
                    <>
                      <span className="p-input-icon-left p-input-icon-right w-6/12">
                        <i className="pi pi-lock" />
                        <InputText
                          autoComplete="on"
                          placeholder="Password (8 or more characters)"
                          className="w-full pb-2 pt-2"
                          name="newPassword"
                          disabled={isSystemAdminImpersonating}
                          type={!passwordEye ? "password" : "text"}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            trigger("confirmPassword");
                          }}
                        />
                        {!passwordEye ? (
                          <i
                            className="pi pi-eye-slash cursor-pointer"
                            onClick={handlePasswordClick}
                          />
                        ) : (
                          <i
                            className="pi pi-eye cursor-pointer"
                            onClick={handlePasswordClick}
                          />
                        )}
                      </span>
                    </>
                  )}
                />
                {errors.newPassword && (
                  <p className="mt-1 text-red-500">
                    {errors.newPassword.message}
                  </p>
                )}
              </div>
              <div className="pt-4">
                <label className="mb-2 block capitalize">
                  Confirm Password <span className="text-red-600">*</span>
                </label>
                <Controller
                  name="confirmPassword"
                  control={control}
                  rules={{
                    required: "Confirm Password is required",
                    validate: (value, formValues) => {
                      return value === formValues.newPassword
                        ? true
                        : "Passwords do not match";
                    },
                  }}
                  render={({ field }) => (
                    <span className="p-input-icon-left p-input-icon-right w-6/12">
                      <i className="pi pi-lock" />
                      <InputText
                        autoComplete="on"
                        placeholder="Confirm password"
                        className="w-full pb-2 pt-2"
                        name="confirmPassword"
                        disabled={isSystemAdminImpersonating}
                        type={!confirmPasswordEye ? "password" : "text"}
                        {...field}
                      />
                      {!confirmPasswordEye ? (
                        <i
                          className="pi pi-eye-slash cursor-pointer"
                          onClick={handleConfirmPasswordClick}
                        />
                      ) : (
                        <i
                          className="pi pi-eye cursor-pointer"
                          onClick={handleConfirmPasswordClick}
                        />
                      )}
                    </span>
                  )}
                />
                {errors.confirmPassword && (
                  <p className="mt-1 text-red-500">
                    {errors.confirmPassword.message}
                  </p>
                )}
              </div>

              <div className="flex flex-col-reverse items-center justify-center sm:justify-end md:flex-row md:gap-5">
                <div className="w-full md:w-auto">
                  {!isAdmin && (
                    <Button
                      onClick={handleCancel}
                      disabled={isSystemAdminImpersonating}
                      type="button"
                      className="mt-2 w-full justify-center rounded-full py-2.5 font-inter font-medium capitalize text-light-purple md:mt-9 md:w-auto"
                    >
                      Cancel
                    </Button>
                  )}
                </div>
                <div className="w-full items-end justify-end md:w-auto">
                  {isLoading ? (
                    <div className="text-center">
                      <ProgressSpinner
                        strokeWidth={3}
                        style={{ width: "40px", height: "40px" }}
                      />
                    </div>
                  ) : (
                    <Button
                      type="submit"
                      disabled={isDisabled || isSystemAdminImpersonating}
                      className="mt-9 w-full justify-center rounded-full bg-light-purple py-2.5 font-inter font-normal capitalize text-light-text md:w-auto"
                    >
                      Save Changes
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
