import { useEffect, useState } from "react";

import { Controller } from "react-hook-form";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";

import useAxios from "../../../../hooks/useAxios";
import useTokenData from "../../../../hooks/useTokenData";
import AddExternalProviderForm from "./AddExternalProviderForm";

function ExistingOrderOnFileForm({ control, setValue, errors }) {
  const [fetchExternalProviders, setFetchExternalProviders] = useState(true);
  const [isAddExternalProviderDialogOpen, setIsAddExternalProviderDialogOpen] =
    useState(false);
  const [externalProviders, setExternalProviders] = useState([]);

  const { http } = useAxios();
  const { userName, entityId } = useTokenData();

  useEffect(() => {
    if (fetchExternalProviders) getExternalProviders();
  }, [fetchExternalProviders]);

  const getExternalProviders = async () => {
    try {
      const response = await http.get(
        `/Orders/GetExternalProviders/${entityId}`,
      );
      setExternalProviders(response.data);
      setFetchExternalProviders(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddExternalProvider = (provider) => {
    setValue("orderingProvider", provider, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <Dialog
        header="Add External Provider"
        blockScroll
        dismissableMask
        draggable={false}
        visible={isAddExternalProviderDialogOpen}
        onHide={() => {
          setIsAddExternalProviderDialogOpen(false);
        }}
      >
        <AddExternalProviderForm
          setIsAddExternalProviderDialogOpen={
            setIsAddExternalProviderDialogOpen
          }
          setFetchExternalProviders={setFetchExternalProviders}
          handleAddExternalProvider={handleAddExternalProvider}
        />
      </Dialog>
      <Tooltip target=".on-file-details" className="max-w-sm" />
      <div className="flex flex-col gap-1">
        <h5 className="font-semibold text-gray-700">
          Existing Order On-File Details
        </h5>
        <p className="min-w-sm w-full text-sm text-gray-700">
          A new order will not be created, but you can use an existing order and
          still seamlessly appropriately quote the desired service and capture
          payment from the patient. Please provide the details for the order
          that you already have on-file in your medical record system for
          appropriate record keeping.
        </p>
      </div>

      <div className="flex flex-col gap-1">
        <label
          htmlFor="ExistingOrderDate"
          className="font-semibold text-gray-800"
        >
          Existing Order Date <span className="text-red-500">*</span>{" "}
          <span
            className="on-file-details"
            data-pr-position="top"
            data-pr-tooltip={`The date in your medical record system when the provider signed and approved the order for this service.`}
          >
            <i className="pi pi-info-circle text-lg" />
          </span>
        </label>
        <div>
          <Controller
            name="ExistingOrderDate"
            control={control}
            rules={{ required: "Order Date is required" }}
            render={({ field }) => (
              <Calendar
                {...field}
                showButtonBar
                dateFormat="mm/dd/yy"
                placeholder="MM/DD/YYYY"
                maxDate={new Date()}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
              />
            )}
          />
        </div>
        {errors.ExistingOrderDate && (
          <div className="text-red-500">{errors.ExistingOrderDate.message}</div>
        )}
      </div>
      <div className="flex flex-col gap-1">
        <label
          htmlFor="ExistingOrderReferenceIdentifier"
          className="font-semibold text-gray-800"
        >
          Existing Order Reference Identifier{" "}
          <span
            className="on-file-details"
            data-pr-position="top"
            data-pr-tooltip="The external identifier, if you have it, that is used to uniquely identify this order in your medical record system, for your documentation and cross-reference purposes."
          >
            <i className="pi pi-info-circle text-lg" />
          </span>
        </label>

        <Controller
          name="ExistingOrderReferenceIdentifier"
          control={control}
          render={({ field }) => (
            <InputText
              id="ExistingOrderReferenceIdentifier"
              type="text"
              maxLength={50}
              placeholder="Existing Order Reference Identifier"
              {...field}
              className="w-full"
            />
          )}
        />
      </div>
      <div className="flex flex-col gap-1">
        <label
          htmlFor="orderingProvider"
          className="font-semibold text-gray-800"
        >
          Select Ordering Provider <span className="text-red-500">*</span>
        </label>

        <Controller
          name="orderingProvider"
          control={control}
          rules={{ required: "Ordering Provider is required" }}
          render={({ field }) => (
            <>
              <Dropdown
                {...field}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                panelFooterTemplate={(options) => (
                  <div
                    className={`${options.className} flex-start flex w-full items-center bg-[#f8f9fa]`}
                  >
                    <Button
                      label="Other, Specify"
                      id="addExternalProvider"
                      icon="pi pi-plus"
                      type="button"
                      link
                      severity="info"
                      onClick={() => {
                        setIsAddExternalProviderDialogOpen(true);
                      }}
                    />
                  </div>
                )}
                valueTemplate={(option, props) => {
                  if (option) {
                    return (
                      <div>
                        <span className="font-semibold">
                          {option.fullName} -{" "}
                        </span>
                        <small className="text-sm italic text-gray-500">
                          ({option.npi})
                        </small>
                      </div>
                    );
                  }

                  return <span>{props.placeholder}</span>;
                }}
                itemTemplate={(option) => (
                  <div>
                    <span className="font-semibold">{option.fullName} - </span>
                    <small className="text-sm italic text-gray-500">
                      ({option.npi})
                    </small>
                  </div>
                )}
                options={externalProviders}
                optionLabel="fullName"
                placeholder="Select your provider"
                className="md:w-14rem w-full"
              />
            </>
          )}
        />
        {errors.orderingProvider && (
          <div className="text-red-500">{errors.orderingProvider.message}</div>
        )}
      </div>
      <div className="py-2">
        <Controller
          control={control}
          name="attestation"
          rules={{
            validate: (value) => {
              if (value !== true) {
                return "Please attest to the statement above to proceed with your order";
              } else return true;
            },
          }}
          render={({ field }) => (
            <>
              <span aria-label="attestation" className="flex gap-2">
                <Checkbox
                  inputId={field.name}
                  checked={field.value}
                  inputRef={field.ref}
                  onChange={(e) => {
                    field.onChange(e.checked);
                  }}
                />
                <label htmlFor="attestation" className="cursor-pointer">
                  I, <strong>{userName}</strong>, attest that I have a valid
                  order for the service(s) that are being ordered herein. This
                  valid order is stored in my practice's medical record system
                  and has the signature of a legally valid medical provider with
                  authority to order such service(s). Furthermore, I understand
                  that the reference information provided herein is only for
                  internal documentation, and the responsibility to maintain a
                  legally valid medical order record remains the responsibility
                  of my practice.
                </label>
              </span>
              {errors.attestation && (
                <div className="text-red-500">{errors.attestation.message}</div>
              )}
            </>
          )}
        />
      </div>
    </div>
  );
}

export default ExistingOrderOnFileForm;
